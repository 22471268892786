import { createAction, handleActions } from 'redux-actions';
import {
  keyValueToObject,
  getInstanceId,
  getMunicipality
} from '../../../utils/utils';
import { olUtils } from 'react-openlayers';
import { toast } from '../toast';

// -------------------------------
// Constants
// -------------------------------

const emptyNewSamfunnsDialog = {
  id: undefined,
  uuid: undefined,
  saving: false,
  saveError: false,
  persphone: '',
  persname: '',
  email: '',
  feedback: '',
  topic: '',
};

export const SAMFUNNDIALOGS_LOADING = 'SAMFUNNDIALOGS_LOADING';
export const SAMFUNNSDIALOGS_LOADED = 'SAMFUNNSDIALOGS_LOADED';

export const FEEDBACKS_LOADING = 'FEEDBACKS_LOADING';
export const FEEDBACKS_LOADED = 'FEEDBACKS_LOADED';

export const SAMFUNNSTOPICS_LOADING = 'SAMFUNNSTOPICS_LOADING';
export const SAMFUNNSTOPICS_LOADED = 'SAMFUNNSTOPICS_LOADED';

export const SET_PROPERTY = 'SET_PROPERTY';
export const CLEAR_NEWSAMFUNNSDIALOG = 'CLEAR_NEWSAMFUNNSDIALOG';

export const SAMFUNNSDIALOG_SAVING = 'SAMFUNNSDIALOG_SAVING';
export const SAMFUNNSDIALOG_SAVED = 'SAMFUNNSDIALOG_SAVED';
export const SAMFUNNSDIALOG_SAVE_FAILED = 'SAMFUNNSDIALOG_SAVE_FAILED';

// -------------------------------
// Actions
// -------------------------------

const samfunnDialogsLoading = createAction(SAMFUNNDIALOGS_LOADING, () => ({}));
const samfunnsDialogLoaded = createAction(SAMFUNNSDIALOGS_LOADED, (dialogs, projCode) => ({
  dialogs,
  projCode
}));

const feedbacksLoading = createAction(FEEDBACKS_LOADING, () => ({}));
const feedbacksLoaded = createAction(FEEDBACKS_LOADED, feedbacks => ({
  feedbacks
}));

const samfunnsTopicsLoading = createAction(SAMFUNNSTOPICS_LOADING, () => ({}));
const samfunnsTopicsLoaded = createAction(SAMFUNNSTOPICS_LOADED, topics => ({ topics }));

const samfunnsDialogSaving = createAction(SAMFUNNSDIALOG_SAVING, () => ({}));
const samfunnsDialogSaved = createAction(SAMFUNNSDIALOG_SAVED, (dialog, projCode) => ({
  dialog,
  projCode
}));
const samfunnsDialogSavedFailed = createAction(SAMFUNNSDIALOG_SAVE_FAILED, data => ({ data }));
export const setProperty = createAction(SET_PROPERTY, (propertyName, propertyValue) => ({ propertyName, propertyValue }));
export const clearNewSamfunnsDialog = createAction(CLEAR_NEWSAMFUNNSDIALOG, () => ({}));

export const saveSamfunnsDialog = instanceName => {
  return (dispatch, getState) => {
    dispatch(samfunnsDialogSaving());
    const state = getState();
    const newDialog = state.samfunnsdialogs.newSamfunnsDialog;

    let params = {
      data: {
        persphone: newDialog.persphone,
        persname: newDialog.persname,
        persemail: newDialog.email,
        feedbacktype: newDialog.feedback,
        topic: newDialog.topic,
        instanceid: getInstanceId(instanceName),
        geom: newDialog.geom
      },
      comment: newDialog.description || ""
    };

    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/SaveSamfunnsDialog',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        if (res.success) {
          const data = keyValueToObject(res.data);

          if (data.feedback) {
            dispatch(toast('feedback', 'success', data.feedback || '', true));
          }
          if (data.record) {
            dispatch(loadSamfunnsDialogs(instanceName));
          } else {
            // dispatch(routeActions.pushPath('/' + instanceName));
          }
        } else {
          dispatch(
            toast('samfunnsDialogSavedFailed', 'danger', res.exception.msg, true)
          );
          return;
        }
        dispatch(samfunnsDialogSaved(res.success && res.data, state.map.projCode));
      })
      .catch(e => {
        dispatch(samfunnsDialogSavedFailed(undefined));
        dispatch(toast('samfunnsDialogSavedFailed', 'danger', undefined, true));
      });
  };
};

export const loadSamfunnsDialogs = (instanceName, filterString) => {
  return (dispatch, getState) => {
    dispatch(samfunnDialogsLoading());
    const state = getState();
    const filter = filterString || '';

    let params = {
      instanceid: getInstanceId(instanceName),
      municipality: getMunicipality(instanceName),
      filter
    };
    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadSamfunnSuggestions',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        dispatch(samfunnsDialogLoaded(res.success && res.records, state.map.projCode));
      })
      .catch(e => dispatch(samfunnsDialogLoaded(undefined)));
  };
};

export const loadFeedbacks = instanceName => {
  return (dispatch, getState) => {
    dispatch(feedbacksLoading());

    let params = {
      instanceid: getInstanceId(instanceName),
      tablename: 'tbl_samfunn_point',
      fieldname: 'feedbacktype',
      relcode: '',
      isPublic: true
    };

    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadCodes',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        dispatch(feedbacksLoaded(res.success && res.records));
      })
      .catch(e => dispatch(feedbacksLoaded(undefined)));
  };
};

export const loadSamfunnsTopics = instanceName => {
  return (dispatch, getState) => {
    dispatch(samfunnsTopicsLoading());
    let params = {
      instanceid: getInstanceId(instanceName),
      tablename: 'tbl_samfunn_point',
      fieldname: 'topic',
      relcode: '',
      isPublic: true
    };
    fetch(
      //'http://localhost/a_a3/' + 
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadCodes',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        dispatch(samfunnsTopicsLoaded(res.success && res.records));
      })
      .catch(e => dispatch(samfunnsTopicsLoaded(undefined)));
  };
};

export const actions = {
  loadSamfunnsDialogs,
  setProperty,
  loadFeedbacks,
  loadSamfunnsTopics,
  clearNewSamfunnsDialog,
  saveSamfunnsDialog
};

function createDate(dateString) {
  if (dateString.substring('/Date')) {
    dateString = dateString.replace('/Date(', '');
    dateString = dateString.replace(')/', '');
    var date = new Date(parseInt(dateString, 10));
    return date;
  }
  return new Date();
}

// -------------------------------
// Reducer
// -------------------------------

export default handleActions(
  {
    SAMFUNNSDIALOG_SAVING: state => {
      return Object.assign({}, state, {
        newSamfunnsDialog: Object.assign({}, state.newSamfunnsDialog, {
          saving: false,
          saveError: false
        })
      });
    },

    SAMFUNNSDIALOG_SAVED: (state, { payload }) => {
      if (payload.dialog) {
        const data = keyValueToObject(payload.dialog);

        if (data.feedback) {
          return Object.assign({}, state, {
            newSamfunnsDialog: Object.assign({}, state.newSamfunnsDialog, {
              uuid: data.record.uuid,
              id: data.record.id,
              saving: false
            })
          });
        } else {
          return Object.assign({}, state, {
            newSamfunnsDialog: Object.assign({}, state.newSamfunnsDialog, {
              uuid: data.record.uuid,
              id: data.record.id,
              saving: false
            })
          });
        }
      } else {
        return Object.assign({}, state, {
          newSamfunnsDialog: Object.assign({}, state.newSamfunnsDialog, {
            saving: false,
            saveError: true
          })
        });
      }
    },

    SAMFUNNSDIALOG_SAVE_FAILED: (state, { payload }) => {
      return Object.assign({}, state, {
        newSamfunnsDialog: Object.assign({}, state.newSamfunnsDialog, {
          saving: false,
          saveError: true
        })
      });
    },

    SAMFUNNDIALOGS_LOADING: state => {
      return Object.assign({}, state, {
        loading: true
      });
    },

    SAMFUNNSDIALOGS_LOADED: (state, { payload }) => {
      if (payload.dialogs) {
        let dialogs = payload.dialogs.map(data => {
          const {
            treatment,
            id,
            date_created,
            topic,
            countpublicmessages,
            countmedia,
            logs,
            geom
          } = data;
          const f = olUtils.createFeatureFromWkt(geom);
          const date = createDate(date_created);

          return {
            treatment,
            id,
            date,
            topic,
            countpublicmessages,
            countmedia,
            logs,
            feature: f
          };
        });

        return Object.assign({}, state, {
          loading: false,
          samfunnsdialogs: dialogs
        });
      } else {
        return Object.assign({}, state, {
          loading: false
        });
      }
    },

    FEEDBACKS_LOADING: state => {
      return Object.assign({}, state, {
        feedbacksLoading: true
      });
    },

    FEEDBACKS_LOADED: (state, { payload }) => {
      if (payload.feedbacks) {
        let feedbacks = payload.feedbacks.map(data => {
          const { code } = data;
          return {
            code
          };
        });

        return Object.assign({}, state, {
          feedbacksLoading: false,
          feedbacks: feedbacks
        });
      } else {
        return Object.assign({}, state, {
          feedbacksLoading: false
        });
      }
    },

    SAMFUNNSTOPICS_LOADING: state => {
      return Object.assign({}, state, {
        samfunnsTopicsLoading: true
      });
    },

    SAMFUNNSTOPICS_LOADED: (state, { payload }) => {
      if (payload.topics) {
        let topics = payload.topics.map(data => {
          const { code, codeid, tag } = data;
          let tags;
          if (tag) {
            tags = keyValueToObject(JSON.parse(tag));
          }

          return {
            code,
            codeid,
            tag: tags
          };
        });
        return Object.assign({}, state, {
          samfunnsTopicsLoading: false,
          samfunnstopics: topics
        });
      } else {
        return Object.assign({}, state, {
          samfunnsTopicsLoading: false
        });
      }
    },

    SET_PROPERTY: (state, { payload }) => {
      const pName = payload.propertyName;
      const pVal = payload.propertyValue;

      var newState = Object.assign({}, state.newSamfunnsDialog);
      newState[pName] = pVal;
      return Object.assign({}, state, {
        newSamfunnsDialog: newState
      });
    },
  

    CLEAR_NEWSAMFUNNSDIALOG: (state, { payload }) => {
      return Object.assign({}, state, {
        newSamfunnsDialog: Object.assign({}, state.newSamfunnsDialog, emptyNewSamfunnsDialog)
      });
    }

  },
  {
    loading: false,
    loadState: {},
    samfunndialogs: [],
    samfunnstopics: [],
    feedbacks: [],
    newSamfunnsDialog: emptyNewSamfunnsDialog
  }
);
