import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import { Button, Glyphicon } from 'react-bootstrap';
// import { actions as mapActions } from '../redux/modules/map';
import { actions as dialogActions } from '../redux/modules/dialog/dialog';
import { VectorLayer, provideMapState } from 'react-openlayers';
import './DialogMessages.scss';
import * as routeActions from 'redux-simple-router';
import Dot from '../components/symbol/Dot';

const mapDispathToProps = dispatch =>
  bindActionCreators(Object.assign({}, dialogActions, routeActions), dispatch);

const mapStateToProps = state => ({
  dialogId: state.dialog.id,
  dialog: state.dialog.dialog,
  dialogLoading: state.dialog.loading,
  mapZoom: state.map.zoom,
  expanded: state.menuButton.expanded
});

export class DialogMessages extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number,
    dialogId: PropTypes.number,
    instanceName: PropTypes.string,
    readBaseUrl: PropTypes.string,
    dialog: PropTypes.object,
    dialogLoading: PropTypes.bool,
    pushPath: PropTypes.func.isRequired,
    loadDialog: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    setCenter: PropTypes.func.isRequired,
    fitViewAndZoom: PropTypes.func.isRequired,
    mapZoom: PropTypes.number,
    expanded: PropTypes.bool
  };

  componentDidMount() {
    this.checkDialog(this.props);
  }

  componentDidUpdate(prevProps) {
    this.checkDialog(this.props);

    if (this.props.id && this.props.dialog) {
      this.highlightDialog(this.props.dialog);
    }
  }

  unhighlightDialog(dialog) {
    if (dialog) {
      dialog.active = false;
      if (dialog.feature) {
        dialog.feature.set('active', false);
      }
    }
  }

  highlightDialog(dialog) {
    if (dialog && !dialog.active) {
      this.unhighlightDialog(dialog);
      dialog.active = true;

      if (dialog.feature) {
        dialog.feature.set('active', true);

        // const zoom = this.props
        //   .mapZoom; /* Math.max(this.props.mapZoom || 0, 18); */
        // let padding = [0, 0, 0, 0];

        // if (window.innerWidth > 768) {
        //   padding[1] = 350;
        // }
        const zoom = 20;
        setTimeout(() => {
          this.props.setCenter(
            dialog.feature.getGeometry().getFirstCoordinate(),
            zoom
          );
        }, 250);
      }
    }
  }

  checkDialog(props) {
    const { id, dialogId, loadDialog } = props;

    if (dialogId !== id) {
      loadDialog(id);
      return;
    }
  }

  formatDateTime(date) {
    const locale = this.props.t('locale');
    const opts = {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    };
    return date.toLocaleString(locale, opts);
  }

  formatDate(date) {
    const locale = this.props.t('locale');
    const opts = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    };
    return date.toLocaleString(locale, opts);
  }

  renderDialogMessage(dialogMessage) {
    return (
      <div className={'dialogMessage'} key={dialogMessage.id}>
        <div className={'table'}>
          <div>
            <div className={'date'}>
              {this.formatDateTime(dialogMessage.date)}
            </div>
            <div className={'dialogMessageBody'}>{dialogMessage.comment}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { dialogId, dialog, dialogLoading, onClose, t } = this.props;

    return (
      // <div className="window-overlay">
      <div className={['', 'window', dialogLoading ? 'loading' : ''].join(' ')}>
        <div className={'headerBar'}>
          <h4 className={'header'}>
            {t('message')} #{dialogId}
          </h4>
          <div className={'buttonBar rightAlign'}>
            <Button
              bsStyle="default"
              onClick={() => {
                let padding = [0, 0, 0, 0];
                if (window.innerWidth > 768) {
                  padding[1] = 350;
                }
                this.props.setCenter(
                  dialog.feature.getGeometry().getFirstCoordinate(),
                  16
                );
              }}
              title={t('zoomIn')}
            >
              <Glyphicon glyph="zoom-in" />
            </Button>
            <Button
              bsStyle="default"
              onClick={() => {
                dialog.feature.set('active', false);
                onClose();
              }}
              title={t('close')}
            >
              <Glyphicon glyph="remove" />
            </Button>
          </div>
        </div>

        {dialog && (
            <VectorLayer
              key={'dialogMessage'}
              layerName={'dialogMessage'}
              features={[dialog.feature]}
              fillColor={dialog.fillColor}
              staticLayer
            />
          ) && (
            <div>
              <div className={'table'}>
                <div className={'row'}>
                  <div className={'atr'}>{t('date')}</div>
                  <div className={'val'}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.formatDate(dialog.date)
                      }}
                    />
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'atr'}>{t('topic')}</div>
                  <div className={'val'}>{dialog.topic}</div>
                </div>
                <div className={'row'}>
                  <div className={'atr'}>{t('detail')}</div>
                  <div className={'val'}>{dialog.detail}</div>
                </div>
                <div className={'row'}>
                  <div className={'atr'}>{t('obsaddress')}</div>
                  <div className={'val'}>{dialog.obsadress}</div>
                </div>
                <div className={'row'}>
                  <div className={'atr'}>{t('status')}</div>
                  <div className={'val'} style={{transform: `translateY(-4px)`}}>
                    <Dot color={dialog.fillColor} className={'statusCircle'} />
                    {dialog.status}
                  </div>
                </div>
              </div>
              {dialog.countpublicmessages > 0 && (
                <div className={'comment'}>{t('comment')}</div>
              )}
              {dialog.logs &&
                dialog.logs.map(log => this.renderDialogMessage(log))}
            </div>
          )}
      </div>
      // </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(translate('DialogMessages')(provideMapState(DialogMessages)));
