export function keyValueToObject(keyValues) {
  let newObject = {};
  keyValues.forEach(obj => {
    newObject[obj.key] = obj.value;
  });
  return newObject;
}

export function formatAdressTitle(addressObject) {
  let address = addressObject.title || '';
  if (addressObject.layerId === 'AdaptiveAddresses') {
    var desc = addressObject.description;
    desc = desc.slice(0, desc.indexOf('(')).trim();
    address += ', ' + desc;
  }
  return address;
}

export function getInstanceId(instanceName) {
  if (
    window.AdaptiveLight.config.instances[instanceName] &&
    window.AdaptiveLight.config.instances[instanceName].id
  ) {
    return window.AdaptiveLight.config.instances[instanceName].id;
  }
  return window.AdaptiveLight.config.instances.default.id;
}

export function getMunicipality(instanceName) {
  if (
    window.AdaptiveLight.config.instances[instanceName] &&
    window.AdaptiveLight.config.instances[instanceName].municipality
  ) {
    return window.AdaptiveLight.config.instances[instanceName].municipality;
  }
  return 0;
}

export const fixKeyValueArraysToJsObject = records => {
  return records.map(r => {
    return Object.assign({}, { [r.key]: r.value, type: r.__type });
  });
};
