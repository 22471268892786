import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import translations from './translations/translations';
import ToastService from './containers/ToastService';
import { actions as sessionActions } from './redux/modules/search/session';
import { actions as instanceActions } from './redux/modules/dialog/instance';
import initMap from './utils/dialog/init-map.js';
import Router from './router';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

var instanceUnsubsriberCallback = null;
var instanceLoadState = false;
window.dialogPrefix = '';

const compareAppVersions = async () => {
  const response = await fetch('/meta.json'); // fetch avoids caching
  const meta = await response.json();
  var latestVersion = meta.version;
  var currentVersion = global.appVersion;
  
  console.log(`latest version: ${latestVersion} - detected version: ${currentVersion}`);
  const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
  // if (shouldForceRefresh) {
  //   console.log(`We have a new version - ${latestVersion}. Should force refresh`);
  // } else {
  //   console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
  // }
  return shouldForceRefresh
}


const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const runCacheBust = async () => {
  console.log('Clearing cache and hard reloading...')
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    let names = await caches.keys();
    for (let name of names) caches.delete(name);
  }
  // delete browser cache and hard reload
  window.location.reload(true);
}

const manifest = {
  "short_name": "Adaptive Dialog",
  "name": "Adaptive Dialog",
  "icons": [
    {
      "src": "/android-chrome-96x96.png",
      "sizes": "96x96",
      "type": "image/png"
    }
  ],
  "display": "standalone",
  "theme_color": "#ffffff",
  "background_color": "#ffffff"
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      instance: null,
      initDone: false
    };
  }

  async componentDidMount() {
    const refreshNeeded = await compareAppVersions();
    if (refreshNeeded) {
      await runCacheBust();
    }

    const { loadSearchSession } = sessionActions;
    const { loadInstance } = instanceActions;
    instanceUnsubsriberCallback = this.props.store.subscribe((a, b, c) => {
      const storeState = this.props.store.getState();
      if (
        instanceLoadState === true &&
        !storeState.instanceReducer.loading &&
        !storeState.instanceReducer.instance
      ) {
        console.log(
          'Fetching instance ' +
            storeState.instanceReducer.friendlyname +
            ' failed. Attempting to read hardcoded config'
        );

        const instance =
          window.__DIALOG__INSTANCES__[storeState.instanceReducer.friendlyname];
        window.AdaptiveLight.config.instances[
          storeState.instanceReducer.friendlyname
        ] = instance;
        instance === undefined
          ? console.log('...failed')
          : console.log('...success');
        initMap(storeState.instanceReducer.friendlyname);
        this.setState({ instance: instance, initDone: true });
      }
      instanceLoadState = storeState.instanceReducer.loading;
      if (
        storeState &&
        storeState.instanceReducer.instance &&
        !this.state.instance
      ) {
        window.AdaptiveLight.config.instances[
          storeState.instanceReducer.instance.friendlyname
        ] = storeState.instanceReducer.instance;
        console.log(
          'Successfully fetched config for ' +
            storeState.instanceReducer.friendlyname
        );
        initMap(storeState.instanceReducer.friendlyname);
        this.createManifest(storeState.instanceReducer.instance.name, storeState.instanceReducer.instance.friendlyname);
        this.setState({
          instance: storeState.instanceReducer.instance,
          initDone: true
        });
      }
    });
    this.props.store.dispatch(loadSearchSession());

    // Load instance config from server
    let instanceName = window.location.pathname.substring(1).split('/')[0];
    if (instanceName === 'dialog') {
      window.dialogPrefix = '/dialog';
      instanceName = window.location.pathname.substring(1).split('/')[1];
    }
    if (instanceName === 'samfunnsdialog') {
      window.dialogPrefix = '/samfunnsdialog';
      instanceName = window.location.pathname.substring(1).split('/')[1];
      window.AdaptiveLight.config.appName = "Adaptive Samfunnsdialog"
    }
    console.log('Fetching config for instance: ' + instanceName);
    this.props.store.dispatch(loadInstance(instanceName));
  }

  componentWillUnmount() {
    if (instanceUnsubsriberCallback) {
      instanceUnsubsriberCallback();
    }
  }

  getTranslations(lang) {
    return Object.assign(translations[lang]);
  }

  createManifest(instanceName, friendlyname) {
    const isSamfunnsdialog = window.location.href.search("samfunnsdialog") > 0;
    manifest.short_name = instanceName;
    manifest.name = instanceName + " | Adaptive " + (isSamfunnsdialog ? "Samfunnsdialog" : "Dialog");
    manifest.start_url = window.location.origin + "/" + (isSamfunnsdialog ? "samfunnsdialog" : "dialog");
    manifest.start_url += "/" + friendlyname;
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestUrl = URL.createObjectURL(blob);
    document.querySelector('#manifest-placeholder').setAttribute('href', manifestUrl);
  }

  render() {
    const { store } = this.props;
    const { instance, initDone } = this.state;

    const defaultLang = instance ? instance.defaultlanguage || 'no' : 'no';
    if (initDone) {
      return (
        <Provider store={store}>
          <TranslatorProvider translations={this.getTranslations(defaultLang)}>
            <Router instance={instance}>
              <ToastService />
            </Router>
          </TranslatorProvider>
        </Provider>
      );
    }
    return null;
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
