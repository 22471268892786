export default {
  config: {
    appName: 'Adaptive Dialog',
    baseUrl: '/',
    instanceUrl: 'https://adialog.no/',
    mapserverUrl: 'https://maps.adialog.no/',
    mapUrl: 'https://opencache.statkart.no/gatekeeper/gk/gk.open',
    mapLayerName: 'topo4graatone',
    defaultRoute: 'dialog',
    defaultLanguage: 'no',
    meldingsDigiThemeUuid: 'add1642e-1bc7-4925-94c2-da1798f7be7a',
    samfunnsDigiThemeUuid: '1d1cd42c-97fd-4924-97f0-6c1ae9b09b12',
    mapProjCode: '3857',
    map: {
      view: {
        center: [1194176, 8381308],
        zoom: 8,
        minZoom: 6
      }
    },
    instances: {},
    searchSources: [
      {
        layerName: 'Stedsnavn',
        dataIndex: 'AdaptiveNames',
        fillColor: '#FFFF00'
      },
      {
        layerName: 'Adresser',
        dataIndex: 'AdaptiveAddresses',
        fillColor: '#FFFF00',
        fontColor: '#000000'
      }
    ],
    posStatus: [
      {
        dataIndex: 'Ubehandlet',
        fillColor: '#cc0000',
        strokeColor: '#FFFFFF',
        fillOpacity: 50,
        strokeOpacity: 50,
        strokeWidth: 2
      },
      {
        dataIndex: 'Under behandling',
        fillColor: '#FFC300',
        strokeColor: '#FFFFFF',
        fillOpacity: 50,
        strokeOpacity: 50,
        strokeWidth: 2
      },
      {
        dataIndex: 'Behandlet',
        fillColor: '#009933',
        strokeColor: '#FFFFFF',
        fillOpacity: 50,
        strokeOpacity: 50,
        strokeWidth: 2
      },
      {
        dataIndex: 'Utbedret',
        fillColor: '#696969',
        strokeColor: '#FFFFFF',
        fillOpacity: 50,
        strokeOpacity: 50,
        strokeWidth: 2
      }
    ],
    posSamfunnsStatus: [
      {
        dataIndex: 'Åpen',
        fillColor: '#cc0000',
        strokeColor: '#FFFFFF',
        fillOpacity: 50,
        strokeOpacity: 50,
        strokeWidth: 2
      },
      {
        dataIndex: 'Lukket',
        fillColor: '#696969',
        strokeColor: '#FFFFFF',
        fillOpacity: 50,
        strokeOpacity: 50,
        strokeWidth: 2
      }
    ]
  }
};
