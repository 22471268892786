import { createAction, handleActions } from 'redux-actions';
// import { keyValueToObject } from '../../../utils/utils';
// import { getStatus } from '../../../utils/dialog/dialog';
// import { actions as mapActions } from '../map';
// import { olUtils } from 'react-openlayers';

// ------------------------------
// Constants
// ------------------------------
export const INSTANCE_LOADING = 'INSTANCE_LOADING';
export const INSTANCE_LOADED = 'INSTANCE_LOADED';

// ------------------------------
// Actions
// ------------------------------
export const instanceLoaded = createAction(
  INSTANCE_LOADED,
  (friendlyname, instance) => ({ friendlyname, instance })
);
const instanceLoading = createAction(INSTANCE_LOADING, friendlyname => ({
  friendlyname
}));

export const loadInstance = friendlyname => {
  return dispatch => {
    dispatch(instanceLoading(friendlyname));
    const filter = {
      filterColumns: [
        {
          name: 'friendlyname',
          value: friendlyname,
          comparisonOperator: '=',
          netType: 'Text',
          logicalOperator: 'AND'
        }
      ],
      sortColumns: [
        {
          name: 'id',
          sortDesc: true
        }
      ],
      secondFilter: [],
      defaultFilter: [],
      distinctColumns: []
    };
    let params = {
      start: 0,
      limit: 1,
      filter
    };

    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Instance.asmx/Read',
      //'http://localhost/a_a3/WebServices/dialog/Instance.asmx/Read',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        var instanceObj = null;
        if (res.success && res.records.length) {
          instanceObj = res.records[0];
        }

        dispatch(instanceLoaded(friendlyname, res.success && instanceObj));
      })
      .catch(e => dispatch(instanceLoaded(undefined)));
  };
};

export const actions = {
  loadInstance
};

// --------------------------
// Reducer
// --------------------------
export default handleActions(
  {
    INSTANCE_LOADED: (state, { payload }) => {
      if (payload.instance) {
        return Object.assign({}, state, {
          loading: false,
          friendlyname: payload.friendlyname,
          instance: payload.instance
        });
      } else {
        return Object.assign({}, state, {
          loading: false,
          dialog: undefined
        });
      }
    },

    INSTANCE_LOADING: (state, { payload }) => {
      return Object.assign({}, state, {
        loading: true,
        friendlyname: payload.friendlyname
      });
    }
  },
  {
    loading: false,
    friendlyname: undefined,
    instance: undefined
  }
);
