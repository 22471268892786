import React from "react";
import "./FilterList.scss";
import PropTypes from 'prop-types';
import Icon from "./Icon";

const FilterList = (props) => {
  const visible = props.displayFilter;
  const filterItemName = props.filterItemName || "meldinger";
  
  const onClickedFilteredItems = (key) => {
    var filteredItem = props.filterItems.find((fi) => {
        return fi.key === key;
    });
    props.filterItemClicked(filteredItem);
  }

  return (
    <div className={visible ? "container-fluid" : ""}>
      <div className={visible ? "filter-container visible" : "filter-container"}>
      <div className="filter-header">Filtrer {filterItemName.toLowerCase()}</div>
        <div className="filter-list">
          {props.filterItems.map((fi) => (
            <div key={fi.key} className="filter-list--row" onClick={() => onClickedFilteredItems(fi.key)}>
              <div className={"checkbox"}>
                {fi.active && <Icon name="checkmark" />}
              </div>
              <div className="text">{fi.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

FilterList.propTypes = {
    displayFilter: PropTypes.bool.isRequired,
    filterItems: PropTypes.array.isRequired,
    filterItemClicked: PropTypes.func.isRequired
  };

export default FilterList;
