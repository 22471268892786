export default {
  locale: 'no',

  Search: {
    Search: 'Søk...',
    Loading: 'Laster...',
    Clear: 'Fjern'
  },

  ArticleList: {
    ReadMore: 'Les mer',
    Loading: 'Laster...'
  },

  ArticleWindow: {
    OpenExternal: 'Åpne kildeartikkel i nytt vindu',
    Close: 'Lukk',
    Loading: 'Laster artikkel...',
    LoadError: 'Kunne ikke laste artikkel.'
  },

  ArticleComments: {
    locale: 'nb-NO',
    Comment: 'Skriv en kommentar...',
    DisplayName: 'Visningsnavn',
    Email: 'E-post (ikke vist)',
    Submit: 'Publiser',
    CouldNotPostComment: 'En feil oppstod.',
    Publishing: 'Publiserer...',
    Hide: 'Skjul'
  },

  Comments: {
    locale: 'nb-NO',
    Previous: 'Forrige',
    Next: 'Neste',
    Page: 'Side',
    Of: 'av',
    Flag: 'Rapporter som upassende',
    Flagged: 'Rapportert'
  },

  SearchSource: {
    locale: 'nb-NO',
    showAllResults: 'Vis alle treff',
    goBack: 'Tilbake'
  },

  Dialog: {},

  DialogView: {
    createNew: 'Opprett ny melding',
    dialogs: 'Meldinger',
    latest: 'siste',
    messages: 'meldinger'
  },

  DialogList: {
    locale: 'nb-NO',
    comments: 'Se kommentar..',
    NoResults: 'Ingen resultater funnet.'
  },

  DialogMessages: {
    locale: 'nb-NO',
    date: 'Dato:',
    topic: 'Fag:',
    detail: 'Kode:',
    obsaddress: 'Obs.adresse:',
    status: 'Status:',
    comment: 'Kommentarer',
    message: 'Melding',
    saveSuccess: 'Melding lagret!',
    zoomIn: 'Zoom til melding',
    close: 'Lukk melding'
  },

  SamfunnsDialogSuggestion: {
    locale: 'nb-NO',
    date: 'Dato:',
    topic: 'Type:',
    status: 'Status:',
    comment: 'Kommentarer',
    message: 'Innspill',
    saveSuccess: 'Innspill lagret!',
    zoomIn: 'Zoom til innspill',
    close: 'Lukk innspill',
    description: 'Beskrivelse'
  },

  SamfunnsDialogList: {
    locale: 'nb-NO',
    comments: 'Se kommentar..',
    NoResults: 'Ingen resultater funnet.',
    Loading: 'Laster data'
  },

  DialogForm: {
    reporter: 'Melder',
    phonePlaceholder: 'Telefon',
    namePlaceholder: 'Navn',
    addressPlaceholder: 'Adresse',
    emailPlaceholder: 'E-post',
    topicPlaceholder: 'Velg tema',
    detailPlaceholder: 'Velg beskrivende kode',
    descriptionPlaceholder: 'Supplerende kommentar',
    place: 'Plassering og kommentar',
    save: 'Send inn',
    cancel: 'Avbryt',
    feedbackPlaceholder: 'Velg tilbakemelding',
    persAdressPlaceHolder: 'Melders adresse/sted',
    obsAdressPlaceHolder: 'Adresse/sted (eller pek i kart)',
    saveError: 'Kunne ikke lagre, vennligst prøv igjen',
    approveMessage:
      'Jeg har lest og godtar at ved å bruke denne tjenesten godkjenner jeg at mine innsendte data benyttes i samsvar med ',
    privacyPolicy: 'personvernerklæringen',
    ThankYouForContributing: 'Takk for ditt bidrag',
    SavingImage: 'Lagrer bilde',
    AddImage: 'Legg til bilde'
  },

  SamfunnsDialogForm: {
    phonePlaceholder: 'Telefon',
    namePlaceholder: 'Navn',
    emailPlaceholder: 'E-post',
    topicPlaceholder: 'Velg tema',
    descriptionPlaceholder: 'Beskriv ditt innspill - teksten vil bli synlig offentlig i samfunnsdialog',
    save: 'Send inn',
    cancel: 'Avbryt',
    feedbackPlaceholder: 'Velg tilbakemelding',
    saveError: 'Kunne ikke lagre, vennligst prøv igjen',
    approveMessage:
      'Jeg har lest og godtar at ved å bruke denne tjenesten godkjenner jeg at mine innsendte data benyttes i samsvar med ',
    privacyPolicy: 'personvernerklæringen',
    ThankYouForContributing: 'Takk for ditt bidrag',
    SavingImage: 'Lagrer bilde',
    AddImage: 'Legg til bilde',
    Receipt: 'Kvittering',
    ThanksForYourContribution: 'Takk for ditt innspill. Din henvendelse har fått innspillsnummer',
    YouCanAlsoUpload: ' Du kan også laste opp et bilde dersom du ønsker det. Bildet vil bli knyttet til innspillet, og blir publisert offentlig.',
    Or: 'eller',
    SeeYourSuggestion: 'Se ditt innsendte innspill',
    BackTo: 'Tilbake til',
    AllSuggestions: 'alle innspill'
  },

  SearchAgent: {
    showAllResults: 'Vis alle treff',
    goBack: 'Tilbake'
  },

  MenuButton: {
    buttonText: 'Meny',
    ShowMap: 'Vis kart',
    ShowList: 'Vis liste',
    AboutApp: 'Om Adaptive Dialog',
    AboutSamfunnsApp: 'Om Samfunnsdialog',
    AddNew: 'Opprett ny melding',
    AddNewSuggestion: 'Opprett nytt innspill',
    AddToHomeScreen: 'Legg til på hjemmeskjerm'
  },

  DialogCreateNewView: {
    newDialog: 'Ny melding',
    CreateNewMessage: 'Opprett ny melding',
    PlaceInMap: 'Plasser meldingen din i kartet'
  },

  SamfunnsDialogCreateNewView: {
    newSuggestion: 'Nytt innspill',
    CreateNewSuggestion: 'Opprett nytt innspill',
    PlaceInMap: 'Plasser innspillet ditt i kartet'
  },

  ToastService: {
    dialogSaved: 'Ny melding mottatt',
    samfunnsDialogSaved: 'Nytt innspill mottatt',
    imageUploaded: 'Bilde lastet opp',
    dialogMessage: 'Din melding angående {0} har fått meldingsnummer #{1}',
    samfunnsDialogMessage: 'Ditt innspill angående {0} har fått referanse nr #{1}',
    dialogSavedFailed: 'Manglende data',
    samfunnsDialogSavedFailed: 'Manglende data',
    feedback: 'Automatisk tilbakemelding'
  },

  MouseOverPopup: {
    Suggestion: 'Innspill'
  },

  MapLayout: {
    CreateNewMessage: "Opprett ny melding",
    CreateNewSuggestion: "Opprett nytt innspill"
  },
  
  SamfunnsDialogView: {
    ButtonHeaderText: "Samfunnsdialog - siste innspill",
    createNewSuggestion: 'Opprett nytt innspill',
    suggestions: 'Innspill',
  },

  AboutSamfunnsDialogScreen: {
    DoYouHaveSuggestions: 'Har du noen gode idèer eller forslag til forbedringer',
    ForUs: 'til oss i',
    SendUsYourSuggestion: 'Send oss ditt innspill',
    HowToUse: 'Slik bruker du samfunnsdialog',
    HowToPart1: 'Når du åpner løsningen ser du alle innsendte innspill fra våre innbyggere. Du kan klikke på innspillene, enten i kartet eller i listen for å se alle detaljene, og eventuelle bilder som ble sendt inn med innspillet. Du kan også lese svaret fra oss i kommunen.',
    HowToPart2: 'Hvis du ønsker å sende inn ditt eget forslag kan du trykke på',
    HowToPart3: 'knappen som du finner både i listen og i kartet. I skjemaet legger du inn detaljer på forslaget ditt, og om du eventuelt ønsker en tilbakemelding på e-post eller mobil - i tillegg til at svaret blir publisert i samfunnsdialog.',
    AboutTheSolution: 'Om løsningen',
    AboutPart1: 'Samfunnsdialog er en del av Adaptive Dialog og leveres av',
    AboutPart2: 'Adaptive Dialog er et verktøy for kommuner for å håndtere henvendelser fra publikum. Innbyggerne registrerer hendelser og forslag i enkle publikumsløsninger. Disse håndteres av kommunen gjennom en egen behandlingsløsning. Her kan man enkelt følge opp henvendelser,  og kommunisere med innbyggerne gjennom blant annet SMS og e-post.',
    AboutPart3: 'Ønsker du mer informasjon om Adaptive Dialog?',
    ContactUs: 'Kontakt oss',
    Problems: 'Problemer',
    SendUsEmailAt: 'Send oss en e-post på',
    AddToHomeScreen: 'Legg til på hjemmeskjerm',
    AddToHomeScreenIntroText: 'Adaptive Samfunnsdialog er tilpasset bruk på mobiltelefoner og nettbrett. For å få rask og enkel tilgang til nettsiden kan man legge den til på hjemmeskjermen på telefonen din, slik at du kan starte den på samme måte som applikasjoner på telefonen din.',
    AddToHomeScreenIosHeader: 'Veiledning - iPhone/iPad',
    AddToHomeScreenIosText1: 'Åpne samfunnsdialog i Safari-nettleseren. Trykk på del-ikonet på verktøylinjen',
    AddToHomeScreenIosText2: 'Velg deretter "Legg til på hjem-skjerm", og skriv inn ønsket navn på snarveien.',
    AddToHomeScreenAndroidHeader: 'Veiledning - Android',
    AddToHomeScreenAndroidText1: 'Åpne samfunnsdialog i Chrome-nettleseren. Trykk på knappen oppe i høyrehjørnet for å åpne Chrome-menyen.',
    AddToHomeScreenAndroidText2: 'Velg deretter "Legg til på startsiden", og skriv inn ønsket navn på snarveien.',
  }
};
