import { createAction, handleActions } from 'redux-actions';
import { keyValueToObject } from '../../../utils/utils';
import { getStatus } from '../../../utils/dialog/dialog';
import { actions as mapActions } from '../map';
import { olUtils } from 'react-openlayers';

// ------------------------------
// Constants
// ------------------------------
export const DIALOG_LOADING = 'DIALOG_LOADING';
export const DIALOG_LOADED = 'DIALOG_LOADED';
export const SET_DIALOG_REFRESH = 'SET_DIALOG_REFRESH';

// ------------------------------
// Actions
// ------------------------------
export const dialogLoaded = createAction(
  DIALOG_LOADED,
  (id, dialog, projCode) => ({ id, dialog, projCode })
);
const dialogLoading = createAction(DIALOG_LOADING, id => ({ id }));

const setDialogRefreshNeeded = createAction(SET_DIALOG_REFRESH, () => ({}));

export const loadDialog = dialogId => {
  return (dispatch, getState) => {
    dispatch(dialogLoading(dialogId));
    const state = getState();
    let params = {
      pointId: dialogId
    };

    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadLog',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        const dialogObj = parseDialog(res.success && res.data);

        if (res.success && dialogObj.feature) {
          let padding = [0, 0, 0, 0];
          // If window is on right side, offset center
          if (window.innerWidth > 768) {
            padding[1] = 350;
          }
          dispatch(
            mapActions.setCenter(
              {
                center: dialogObj.feature.getGeometry().getFirstCoordinate(),
                padding
              },
              state.map.zoom
            )
          );
        }
        dispatch(
          dialogLoaded(dialogId, res.success && dialogObj, state.map.projCode)
        );
      })
      .catch(e => dispatch(dialogLoaded(undefined)));
  };
};

export const actions = {
  loadDialog,
  setDialogRefreshNeeded
};

const parseDialog = data => {
  if (!data) {
    return undefined;
  }
  const record = keyValueToObject(data).log;
  // const wktReader = new ol.format.WKT();
  const {
    status,
    id,
    uuid,
    date_modified,
    topic,
    detail,
    obsadress,
    countpublicmessages,
    geom
  } = record;

  let logs = record.logs.map(l => {
    const { logid, date_created, comment } = l;
    return {
      id: logid,
      date: parseDate(date_created),
      comment
    };
  });

  // const f = olUtils.createFeatureFromGeometry(geom);
  const f = olUtils.createFeatureFromWkt(geom);
  const dialog = {
    id,
    uuid,
    status,
    date: parseDate(date_modified),
    topic,
    detail,
    obsadress,
    countpublicmessages,
    feature: f,
    logs: logs,
    fillColor: getStatus(status).fillColor
  };
  return dialog;
};

const parseDate = date => {
  try {
    return new Date(parseInt(date.match(/.Date\((\d+)\)./)[1], 10));
  } catch (ex) {
    return null;
  }
};

// --------------------------
// Reducer
// --------------------------
export default handleActions(
  {
    DIALOG_LOADED: (state, { payload }) => {
      if (payload.dialog) {
        return Object.assign({}, state, {
          loading: false,
          id: payload.id,
          dialog: payload.dialog,
          dialogRefreshNeeded: false
        });
      } else {
        return Object.assign({}, state, {
          loading: false,
          dialog: undefined,
          dialogRefreshNeeded: false
        });
      }
    },

    DIALOG_LOADING: (state, { payload }) => {
      return Object.assign({}, state, {
        loading: true,
        id: payload.id,
        dialogRefreshNeeded: false
      });
    },
    SET_DIALOG_REFRESH: (state, { payload }) => {
      return Object.assign({}, state, {
        dialogRefreshNeeded: true
      });
    }
  },
  {
    loading: false,
    id: undefined,
    dialog: undefined,
    dialogRefreshNeeded: false,
  }
);
