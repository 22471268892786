import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button, FormControl, FormGroup, Glyphicon } from 'react-bootstrap';
import { translate } from 'react-translate';
import { actions as dialogActions } from '../redux/modules/dialog/samfunnsdialogs';
import { actions as toastActions } from '../redux/modules/toast';
import { actions as menuButtonActions } from '../redux/modules/menuButton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UploadButton from './Buttons/UploadButton';
import {
  WmsLayer,
  DrawLayer,
  olUtils,
  provideMapState
} from 'react-openlayers';
import { _searchSources } from '../utils/dialog/dialog';
import { formatAdressTitle } from '../utils/utils';
import Icon from './Icon';
import './DialogForm.scss';

const initialState = {
  approvePrivacyPolicy: false,
  notifyPrivacyPolicy: false,
  savedDialogId: undefined,
  savedDialogUuid: undefined,
  screenWidth: window.innerWidth
};
const mapDispatchToProps = dispatch => {
  return Object.assign(
    {},
    bindActionCreators(
      Object.assign({}, dialogActions, menuButtonActions, toastActions),
      dispatch
    )
  );
};

const mapStateToProps = state => ({
  newDialog: state.samfunnsdialogs.newSamfunnsDialog,
  topics: state.samfunnsdialogs.samfunnstopics,
  feedbacks: state.dialogs.feedbacks,
  mediaSaving: state.mediaReducer.saving,
  mediaError: state.mediaReducer.error,
  map: state.map,
  mapCenter: state.map.center,
  expanded: state.menuButton.expanded
});

export class SamfunnsDialogForm extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    setProperty: PropTypes.func.isRequired,
    topics: PropTypes.array,
    loadSamfunnsTopics: PropTypes.func.isRequired,
    map: PropTypes.object,
    loadFeedbacks: PropTypes.func.isRequired,
    feedbacks: PropTypes.array,
    newDialog: PropTypes.object,
    setCenter: PropTypes.func.isRequired,
    clearNewSamfunnsDialog: PropTypes.func.isRequired,
    setWarning: PropTypes.func.isRequired,
    expand: PropTypes.func.isRequired,
    instanceName: PropTypes.string,
    toast: PropTypes.func.isRequired,
    mediaSaving: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = initialState;

    this.resetState = this.resetState.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const { instanceName } = this.props;
    this.props.loadSamfunnsTopics(instanceName);
    this.props.loadFeedbacks(instanceName);
    window.addEventListener("resize", this.updateWindowDimensions);
    const isMobileView = window.innerWidth < 768;
    if (isMobileView && this.props.expanded) {
      this.props.expand()
    }
  }

  componentWillUnmount() {
    this.props.clearNewSamfunnsDialog();
    this.resetState();
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.newDialog.id === undefined &&
      this.props.newDialog.id !== undefined
    ) {
      this.setState({
        savedDialogId: this.props.newDialog.id,
        savedDialogUuid: this.props.newDialog.uuid
      });
    }
    if (prevProps.mapCenter !== this.props.mapCenter) {
      this.props.setCenter(this.props.mapCenter);
    }
  }

  resetState() {
    this.setState(initialState);
  }

  getTopicObject(topic) {
    const { topics } = this.props;
    let t;

    t = topics.find(o => {
      return o.code === topic;
    });
    return t;
  }

  updateWindowDimensions(e) {
    const isMobileView = window.innerWidth < 768;
    //console.log("view width", window.innerWidth, isMobileView);
    this.setState({ screenWidth: window.innerWidth });
 }

  renderForm() {
    const {
      t,
      onSave,
      onCancel,
      setProperty,
      topics,
      feedbacks,
      map,
      newDialog,
      clearNewSamfunnsDialog,
      setWarning,
      expanded,
      expand
    } = this.props;
    const feature = null; //newDialog.obsadress.feature || newDialog.persadress.feature;
    let topicObject;//, detailObject;
    if (newDialog.topic) {
      topicObject = this.getTopicObject(newDialog.topic);
    }

    const { approvePrivacyPolicy, notifyPrivacyPolicy } = this.state;

    const isMobileView = window.innerWidth < 768;
    // if (isMobileView) {
      
    // }
    return (
      <React.Fragment>
        {topicObject &&
          topicObject.tag &&
          (topicObject.tag['wms_uri'] && topicObject.tag['wms_layer']) && (
            <WmsLayer
              id={String(topicObject.codeid)}
              key={String(topicObject.codeid)}
              uri={topicObject.tag['wms_uri']}
              layerName={topicObject.tag['wms_layer']}
              staticLayer
            />
          )}
        <DrawLayer
          olGeomType="Point"
          layerName="form"
          name="form"
          draw
          features={feature ? [feature] : []}
          onDrawend={feature => {
            var coords = feature.getGeometry().getFirstCoordinate();
            const isMobileView = window.innerWidth < 768;
            if (isMobileView) {
              expand()
            }
            coords = olUtils.transformCoordinate(
              coords,
              map.projCode,
              'EPSG:4326'
            );
            var f = olUtils.createFeatureFromGeometry(feature.getGeometry());
            setProperty('geom', olUtils.getWktFromFeature(f));
          }}
        />
        

        <form className={['form'].join(' ')}>
          <FormGroup>
            <FormControl
              componentClass="select"
              onChange={e => {
                let t = this.getTopicObject(e.target.value);
                if (t && t.tag && t.tag.guidance) {
                  setWarning(t.tag.guidance);
                }
                setProperty('topic', e.target.value);
              }}
              defaultValue={t('topicPlaceholder')}
            >
              <option>{t('topicPlaceholder')}</option>
              {topics.map(topic => (
                <option key={topic.code} value={topic.code}>
                  {topic.code}
                </option>
              ))}
            </FormControl>
          </FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                placeholder={t('namePlaceholder')}
                onChange={e => {

                  setProperty('persname', e.target.value);
                }}
                value={newDialog.persname}
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                type="tel"
                placeholder={t('phonePlaceholder')}
                onChange={e => {
                  setProperty('persphone', e.target.value);
                }}
                value={newDialog.persphone}
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                type="email"
                placeholder={t('emailPlaceholder')}
                onChange={e => {
                  setProperty('email', e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                componentClass="select"
                onChange={e => {
                  setProperty('feedback', e.target.value);
                }}
                defaultValue={t('feedbackPlaceholder')}
              >
                <option>{t('feedbackPlaceholder')}</option>
                {feedbacks.map(feedback => (
                  <option key={feedback.code} value={feedback.code}>
                    {feedback.code}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
        
          <FormGroup>
            <FormControl
              componentClass="textarea" 
              rows="3"
              placeholder={t('descriptionPlaceholder')}
              onChange={e => {
                setProperty('description', e.target.value);
              }}
            />
          </FormGroup>
          <div className="privacy-policy-box">
            <div
              className={
                !approvePrivacyPolicy && notifyPrivacyPolicy
                  ? 'checkbox red'
                  : 'checkbox'
              }
              onClick={() =>
                this.setState({ approvePrivacyPolicy: !approvePrivacyPolicy })
              }
            >
              {approvePrivacyPolicy && <Icon name="checkmark" />}
            </div>
            <div
              className={
                !approvePrivacyPolicy && notifyPrivacyPolicy
                  ? 'text red'
                  : 'text'
              }
            >
              {t('approveMessage')}
              <a
                href="https://www.avinet.no/personvernerklaring.html"
                target="_blank"
              >
                {t('privacyPolicy')}
              </a>
            </div>
          </div>
          <FormGroup>
            <div className={'buttonBar rightAlign'}>
              <input type="button"
                className="btn redButton"
                value={t('cancel')}
                onClick={() => {
                  clearNewSamfunnsDialog();
                  onCancel();
                }} />
              <input
                type="button"
                className={
                  approvePrivacyPolicy
                    ? 'btn btn-primary greenButton'
                    : 'btn btn-primary disabled'
                }
                onClick={() => {
                  if (!approvePrivacyPolicy) {
                    this.setState({ notifyPrivacyPolicy: true });
                    return;
                  }
                  onSave();
                }}
                value={t('save')}
              />
            </div>
          </FormGroup>
        </form>
      </React.Fragment>
    );
  }

  renderReceipt() {
    const { t, mediaSaving } = this.props;
    const uploading = mediaSaving;
    return (
      <div className="receipt">
        <div className="receipt-header">{t("Receipt")}</div>
        <div>
          <p>
            {t("ThanksForYourContribution")}
            {
              <Link
                to={
                  window.dialogPrefix +
                  '/' +
                  this.props.instanceName +
                  '/' +
                  this.state.savedDialogId
                }
              >
                {' #' + this.state.savedDialogId}
              </Link>
            }
          </p>

          <p>
           {t('YouCanAlsoUpload')}
          </p>
        </div>
        <div className="receipt-picture-button-container">
          <form>
            {uploading && (
              <div className={'loadSpinner'}>
                <Glyphicon glyph="refresh" className="rotating" />
                {' ' + t('SavingImage')}
              </div>
            )}
            {
              <UploadButton
                keyName="file-upload"
                recordUuid={this.state.savedDialogUuid}
                themeUuid={window.AdaptiveLight.config.samfunnsDigiThemeUuid}
                labelText={t('AddImage')}
                onSaveSuccess={() =>
                  this.props.toast(
                    'imageUploaded',
                    'success',
                    t('ThankYouForContributing'),
                    true
                  )
                }
              />
            }
          </form>
          <div>{t('Or')}</div>
          <div className='receipt-picture-actions-container'>
            <div className='card' onClick={() => this.props.history.push(window.dialogPrefix + '/' + this.props.instanceName + '/' + this.state.savedDialogId)}>
              <div className='card-icon'><Glyphicon glyph="record" /></div>
              
              <div className='card-text'><Link
                  to={
                    window.dialogPrefix +
                    '/' +
                    this.props.instanceName +
                    '/' +
                    this.state.savedDialogId
                  }
                >
                  {t('SeeYourSuggestion')}
                </Link>
                </div>
            </div>
            <div className='card' onClick={() => this.props.history.push(window.dialogPrefix + '/' + this.props.instanceName)}>
              <div className='card-icon'><Glyphicon glyph="th-list" /></div>
              <div className='card-text'><Link to={window.dialogPrefix + '/' + this.props.instanceName}>
                {t('BackTo')}{' '}
              
                {t('AllSuggestions')}
              </Link></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { savedDialogId } = this.state;
    return (
      <div>
        {!savedDialogId && this.renderForm()}
        {savedDialogId && this.renderReceipt()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('SamfunnsDialogForm')(provideMapState(withRouter(SamfunnsDialogForm))));
