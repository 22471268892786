export default {
  locale: 'en',

  Search: {
    Search: 'Search...',
    Loading: 'Loading...',
    Clear: 'Clear'
  },

  ArticleList: {
    ReadMore: 'Read more',
    Loading: 'Loading...'
  },

  ArticleWindow: {
    OpenExternal: 'Open source article in new window',
    Close: 'Close',
    Loading: 'Loading article...',
    LoadError: 'Could not load article.'
  },

  ArticleComments: {
    locale: 'en-GB',
    Comment: 'Write a comment here...',
    DisplayName: 'Display name',
    Email: 'E-mail (not shown)',
    Submit: 'Publish',
    CouldNotPostComment: 'An error occurred.',
    Publishing: 'Publishing...',
    Hide: 'Hide'
  },

  Comments: {
    locale: 'en-GB',
    Previous: 'Previous',
    Next: 'Next',
    Page: 'Page',
    Of: 'of',
    Flag: 'Flag as inappropriate',
    Flagged: 'Flagged'
  },

  SearchSource: {
    local: 'en-GB',
    showAllResults: 'Show all results',
    goBack: 'Back'
  },

  Dialog: {},

  DialogView: {
    createNew: 'Create new',
    dialogs: 'Dialogs',
    latest: 'siste'
  },

  DialogList: {
    locale: 'en-GB',
    comments: 'See comments..',
    NoResults: 'No results to display'
  },

  DialogMessages: {
    locale: 'en-GB',
    date: 'Date:',
    topic: 'Topic:',
    detail: 'Code:',
    obsaddress: 'Obs.address:',
    status: 'Status:',
    comment: 'Comments',
    message: 'Message',
    saveSuccess: 'Message saved!',
    zoomIn: 'Zoom to message',
    close: 'Close message'
  },

  SamfunnsDialogSuggestion: {
    locale: 'en-GB',
    date: 'Date:',
    topic: 'Topic:',
    status: 'Status:',
    comment: 'Comments',
    message: 'Suggestion',
    saveSuccess: 'Suggestion saved!',
    zoomIn: 'Zoom to suggestion',
    close: 'Close suggestion details',
    description: 'Description'
  },

  SamfunnsDialogList: {
    locale: 'en-GB',
    comments: 'See comments..',
    NoResults: 'No results to display',
    Loading: 'Loading data'
  },

  DialogForm: {
    reporter: 'Reporter',
    phonePlaceholder: 'Phone',
    namePlaceholder: 'Name',
    addressPlaceholder: 'Address',
    emailPlaceholder: 'Email',
    topicPlaceholder: 'Choose topic',
    detailPlaceholder: 'Choose detailed information',
    descriptionPlaceholder: 'Description',
    place: 'Location and description',
    save: 'Save',
    cancel: 'Cancel',
    feedbackPlaceholder: 'Choose feedback type',
    persAdressPlaceHolder: 'Own address/placename',
    obsAdressPlaceHolder: 'Address/place (or point in map)',
    saveError: 'Failed to save, please try again!',
    approveMessage:
      'I have read and accept that my submitted data may be used in accordance with the ',
    privacyPolicy: 'privacy policy',
    ThankYouForContributing: 'Thank you for your contribution',
    SavingImage: 'Saving image',
    AddImage: 'Add image'
  },

  SamfunnsDialogForm: {
    phonePlaceholder: 'Phone',
    namePlaceholder: 'Name',
    emailPlaceholder: 'Email',
    topicPlaceholder: 'Choose topic',
    descriptionPlaceholder: 'Describe your suggestion - your text will be publicly visible in samfunnsdialog',
    save: 'Save',
    cancel: 'Cancel',
    feedbackPlaceholder: 'Choose feedback type',
    saveError: 'Failed to save, please try again!',
    approveMessage:
      'I have read and accept that my submitted data may be used in accordance with the ',
    privacyPolicy: 'privacy policy',
    ThankYouForContributing: 'Thank you for your contribution',
    SavingImage: 'Saving image',
    AddImage: 'Add image',
    Receipt: 'Receipt',
    ThanksForYourContribution: 'Thank you for your contribution. Your suggestion has refenrence number',
    YouCanAlsoUpload: ' You can also upload an image if you wish. The image will be visible publicly as part of your suggestion.',
    Or: 'or',
    SeeYourSuggestion: 'See your suggestion',
    BackTo: 'Back to',
    AllSuggestions: 'all suggestions'
  },

  SearchAgent: {
    showAllResults: 'Show all results',
    goBack: 'Back'
  },

  MenuButton: {
    buttonText: 'Menu',
    ShowMap: 'Show map',
    ShowList: 'Show list',
    AboutApp: 'About Adaptive Dialog',
    AboutSamfunnsApp: 'About Samfunnsdialog',
    AddNew: 'Add new message',
    AddNewSuggestion: 'Add new suggestion',
    AddToHomeScreen: 'Add to home screen'
  },

  DialogCreateNewView: {
    newDialog: 'New inquiry',
    CreateNewMessage: 'Create new message',
    PlaceInMap: 'Place your message in the map'
  },

  SamfunnsDialogCreateNewView: {
    newSuggestion: 'New suggestion',
    CreateNewSuggestion: 'Create new suggestion',
    PlaceInMap: 'Place your suggestion in the map'
  },

  ToastService: {
    dialogSaved: 'New inquiry received',
    samfunnsDialogSaved: 'New suggestion received',
    dialogMessage: "Your inquiry regarding '{0}' was given reference nr #{1}",
    samfunnsDialogMessage: "Your suggestion regarding '{0}' was given reference nr #{1}",
    imageUploaded: 'Image uploaded',
    dialogSavedFailed: 'Missing data',
    samfunnsDialogSavedFailed: 'Missing data',
    feedback: 'Automatic feedback'
  },

  MouseOverPopup: {
    Suggestion: 'Suggestion'
  },

  MapLayout: {
    CreateNewMessage: "Create new message",
    CreateNewSuggestion: "Create new suggestion"
  },
  
  SamfunnsDialogView: {
    ButtonHeaderText: "Samfunnsdialog - latest suggestions",
    createNewSuggestion: 'Create new suggestion',
    suggestions: 'Suggestions',
  },

  AboutSamfunnsDialogScreen: {
    DoYouHaveSuggestions: 'Har du noen gode idèer eller forslag til forbedringer',
    ForUs: 'til oss i',
    SendUsYourSuggestion: 'Send oss ditt innspill',
    HowToUse: 'Slik bruker du samfunnsdialog',
    HowToPart1: 'Når du åpner løsningen ser du alle innsendte innspill fra våre innbyggere. Du kan klikke på innspillene, enten i kartet eller i listen for å se alle detaljene, og eventuelle bilder som ble sendt inn med innspillet. Du kan også lese svaret fra oss i kommunen.',
    HowToPart2: 'Hvis du ønsker å sende inn ditt eget forslag kan du trykke på',
    HowToPart3: 'knappen som du finner både i listen og i kartet. I skjemaet legger du inn detaljer på forslaget ditt, og om du eventuelt ønsker en tilbakemelding på e-post eller mobil - i tillegg til at svaret blir publisert i samfunnsdialog.',
    AboutTheSolution: 'Om løsningen',
    AboutPart1: 'Samfunnsdialog er en del av Adaptive Dialog og leveres av',
    AboutPart2: 'Adaptive Dialog er et verktøy for kommuner for å håndtere henvendelser fra publikum. Innbyggerne registrerer hendelser og forslag i enkle publikumsløsninger. Disse håndteres av kommunen gjennom en egen behandlingsløsning. Her kan man enkelt følge opp henvendelser,  og kommunisere med innbyggerne gjennom blant annet SMS og e-post.',
    AboutPart3: 'Ønsker du mer informasjon om Adaptive Dialog?',
    ContactUs: 'Kontakt oss',
    Problems: 'Problemer',
    SendUsEmailAt: 'Send oss en e-post på',
    AddToHomeScreen: 'Add to home screen',
    AddToHomeScreenIntroText: 'Adaptive Samfunnsdialog is a website intended for usage on mobile devices. For a quick and easy access the website, you can add a shortcut to your home screen, so you can open it just like other apps on your phone.',
    AddToHomeScreenIosHeader: 'How to - iPhone/iPad',
    AddToHomeScreenIosText1: 'Open samfunnsdialog in your mobile Safari browser. Press the share-icon on the toolbar.',
    AddToHomeScreenIosText2: 'Choose "Add to home screen and enter the name you want for the shortcut.',
    AddToHomeScreenAndroidHeader: 'How to - Android',
    AddToHomeScreenAndroidText1: 'Open samfunnsdialog in your mobile Chrome browser. Press the button in the top right corner to open the Chrome menu.',
    AddToHomeScreenAndroidText2: 'Choose "Add to start page", and enter the name you want for the shortcut.',
  }
};
