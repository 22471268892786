import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import { getColor, getIcon } from '../utils/samfunnsdialogHelpers';
import './SamfunnsDialogList.scss';


export class SamfunnsDialogList extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dialogs: PropTypes.array.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
  };

  handleMouseOver(dialog) {
    dialog.feature.set('hover', true);
  }

  handleMouseOut(dialog) {
    dialog.feature.set('hover', false);
  }

  formatDate(date) {
    const locale = this.props.t('locale');
    const opts = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    };
    return date.toLocaleString(locale, opts);
  }

  formatTime(date) {
    const locale = this.props.t('locale');
    const opts = {
      hour: 'numeric',
      minute: 'numeric'
    };
    return date.toLocaleString(locale, opts);
  }

  renderSamfunnsDialogItem(samfunnsdialog) {
    const { onDialogOpen } = this.props;
    const date = this.formatDate(samfunnsdialog.date);
    const time = this.formatTime(samfunnsdialog.date);
    const logs = (samfunnsdialog.logs && samfunnsdialog.logs.length > 0) ? [...samfunnsdialog.logs].sort((a,b) => { return a.logid - b.logid} ) : [];
    const description = (logs && logs.length > 0) ? logs[0].comment : "";

    const icon = getIcon(samfunnsdialog.topic);

    const bgColor = getColor(samfunnsdialog.topic);

    return (
    <div 
      className="samfunnslist--item" 
      style={{backgroundColor: bgColor}}
      key={samfunnsdialog.id} 
      onClick={() => {
        samfunnsdialog.feature.set('active', true);
        onDialogOpen(samfunnsdialog)
      }}
    >
      <div className="icon">
        <img src={icon} alt={samfunnsdialog.topic + '-ikon'} />
      </div>
      <div className="content">
        <div className="content-wrapper">
          <div className="meta-row">{date} {samfunnsdialog.topic}</div>
          <div className="description-row">{description}</div>
        </div>
      </div>
      <div className="status">
        <div className="status-wrapper">
          <div>{samfunnsdialog.countmedia > 0 && <Glyphicon glyph="camera" />}</div>
          <div>{samfunnsdialog.countpublicmessages > 0 && <Glyphicon glyph="comment" />}</div>
        </div>
      </div>
      </div>)
  }

  render() {
    const { t, isLoading, dialogs } = this.props;
    return (
      <div
        className={['samfunnslist', isLoading ? 'loading' : ''].join(' ')}
      >
        {!isLoading && !dialogs.length ? (
          <div className={'noResults'}>
            <p>{t('NoResults')}</p>
          </div>
        ) : (
          dialogs.map(dialog => this.renderSamfunnsDialogItem(dialog))
        )}
        <div className={'loadOverlay'}>
          <Glyphicon glyph="refresh" className="rotating" />
          {t('Loading')}
        </div>
      </div>
    );
  }
}

export default translate('SamfunnsDialogList')(SamfunnsDialogList);
