import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon } from 'react-bootstrap';
// import { connect } from 'react-redux';
import { translate } from 'react-translate';
import './SearchField.scss';
import SearchAgent from './SearchAgent';

export class SearchField extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    sources: PropTypes.array.isRequired,
    fieldKey: PropTypes.string.isRequired,
    map: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    onResultSelected: PropTypes.func.isRequired,
    value: PropTypes.string,
    keepLayer: PropTypes.bool,
    setCenter: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      query: '',
      addressSearchLoading: false,
      searchVisible: false
    };
  }

  onQueryChange(query) {
    this.setState({
      query: query
    });
  }

  render() {
    const {
      t,
      sources,
      fieldKey,
      map,
      placeholder,
      onResultSelected,
      value,
      keepLayer,
      setCenter
    } = this.props;
    const { searchVisible } = this.state;

    return (
      <div className={['form-group', 'searchField'].join(' ')}>
        <div className={'inputWrap'}>
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            ref="queryField"
            onChange={e => {
              onResultSelected({ title: e.target.value });
              this.onQueryChange(e.target.value);
            }}
            onFocus={() => {
              this.setState({ searchVisible: true });
            }}
            value={value}
          />
          <Button
            className="search-clear"
            bsStyle="link"
            title={t('clear')}
            onClick={() => {
              onResultSelected({});
              this.setState({
                query: '',
                searchVisible: false
              });
            }}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </div>
        {this.state.addressSearchLoading && (
          <div className={'loadingElem'}>
            <Glyphicon glyph="refresh" className="rotating" />
            {t('Loading')}
          </div>
        )}
        <div
          className={searchVisible ? 'searchSources' : 'searchSourcesHidden'}
        >
          {sources.map(sSource => (
            <SearchAgent
              fieldKey={fieldKey}
              map={map}
              searchSource={sSource}
              key={sSource.dataIndex}
              query={this.state.query}
              onResultSelected={sObj => {
                onResultSelected(sObj);
                this.setState({ searchVisible: false });
              }}
              visible={this.state.searchVisible}
              keepLayer={keepLayer}
              setCenter={setCenter}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default translate('SearchField')(SearchField);
