export const posStatus = window.AdaptiveLight.config.posStatus || [];
export const posSamfunnsStatus = window.AdaptiveLight.config.posSamfunnsStatus || [];
export const _searchSources = window.AdaptiveLight.config.searchSources || [];

export function getStatus(key) {
  for (var i = 0; i < posStatus.length; ++i) {
    var status = posStatus[i];
    if (status.dataIndex === key) {
      return status;
    }
  }
}

export function getSamfunnsStatus(key) {
  for (var i = 0; i < posSamfunnsStatus.length; ++i) {
    var status = posSamfunnsStatus[i];
    if (status.dataIndex === key) {
      return status;
    }
  }
}

export function getSearchSource(key) {
  for (var i = 0; i < _searchSources.length; ++i) {
    var searchSource = _searchSources[i];
    if (searchSource.dataIndex === key) {
      return searchSource;
    }
  }
}
