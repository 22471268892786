import React from 'react';
import './LandingPageView.scss';
import Logo from '../images/adaptivedialog.png';

const renderInstances = instances => {
  var instanceNames = Object.keys(instances);
  if (instanceNames.length > 0) {
    return (
      <div className="instance-list">
        <h2>Eller gå til en eksisterende dialog-løsning:</h2>
        {instanceNames.map(instanceName => {
          if (instanceName !== 'default') {
            return (
              <div className="instance-link" key={instanceName}>
                <a href={window.dialogPrefix + '/' + instanceName}>
                  {instanceName}
                </a>
              </div>
            );
          } return null;
        })}
      </div>
    );
  }
  return <div>Ingen Dialog-instanser funnet</div>;
};

const LandingPageView = () => {
  // console.log('all instances: ', window.__DIALOG__INSTANCES__);
  return (
    <div className="landing-page">
      <div className="landing-page--header">
        <img src={Logo} alt="adaptive dialog logo" />
        <h1>Adaptive Dialog</h1>
      </div>
      <div className="landing-page--content">
        <p>
          Adaptive Dialog benyttes for å håndtere henvendelser fra publikum. Man
          kan registrere henvendelser, følge opp og utføre kommunikasjon mot
          publikum knyttet til feil og mangler på tekniske tjenester slik som
          vei, bygningsmasse, renovasjon, vann og avløp, gatelys mv.
        </p>

        <div className="cta--container">
          <a
            className="btn btn-primary cta--button"
            href="mailto:avinet@avinet.no?subject=Mer%20informasjon%20om%20Adaptive%20Dialog"
          >
            Kontakt meg om Adaptive Dialog
          </a>
        </div>

        {renderInstances(window.__DIALOG__INSTANCES__)}
      </div>
      <div className="landing-page--footer">
        <div className='wrapper'>
          <div className='about-box'>
            <h1>Om løsningen</h1>
            <p>Samfunnsdialog er en del av Adaptive Dialog og leveres av{' '}
            <a
              href="https://www.avinet.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Asplan Viak Internet AS
            </a>
            </p>
            <p>
            Adaptive Dialog er et verktøy for kommuner for å håndtere henvendelser fra publikum. Innbyggerne registrerer hendelser og forslag i enkle publikumsløsninger. Disse håndteres av kommunen gjennom en egen behandlingsløsning. Her kan man enkelt følge opp henvendelser,  og kommunisere med innbyggerne gjennom blant annet SMS og e-post.
            </p>
            <p>Ønsker du mer informasjon om Adaptive Dialog?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageView;
