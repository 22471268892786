import React from 'react';
import './AboutForm.scss';
import Logo from '../images/adaptivedialog.png';

const AboutForm = ({ onClose }) => {
  return (
    <div className="about-form--container" >
      <div className="about-form">
        <div className="about-form--header">
          <img src={Logo} alt="adaptive dialog logo" />
          <h1>Adaptive Dialog</h1>
        </div>
        <div className="about-form--content">
          <p>
            Adaptive Dialog benyttes for å håndtere henvendelser fra publikum. Man
            kan registrere henvendelser, følge opp og utføre kommunikasjon mot
            publikum knyttet til feil og mangler på tekniske tjenester slik som
            vei, bygningsmasse, renovasjon, vann og avløp, gatelys mv.
          </p>
          <p>Løsningen består av to deler:</p>
          <ul>
            <li>
              En publikumsløsning (denne) hvor man kan registrere og følge opp nye
              henvendelser. Den settes inn i kommunens portal eller benyttes
              selvstendig via en smarttelefon eller nettbrett.
            </li>
            <li>
              En behandlingsløsning som benyttes av saksbehandlere for å håndtere
              henvendelser. Denne kan benyttes på en hvilken som helst PC eller
              nettbrett.
            </li>
          </ul>
        </div>
        <div className="about-form--footer">
          <div>
            Adaptive Dialog er utviklet av{' '}
            <a
              href="https://www.avinet.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Asplan Viak Internet AS
            </a>
          </div>
          <div
            onClick={() => onClose(true)}
            className="btn btn-primary close-button"
          >
            Lukk
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutForm;
