import request from 'superagent';
import { fixKeyValueArraysToJsObject } from '../utils/utils';
import { handleActions, createAction } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
export const DIGI_THEME_MEDIA_SAVING = 'DIGI_THEME_MEDIA_SAVING';
export const DIGI_THEME_MEDIA_SAVE_SUCCESS = 'DIGI_THEME_MEDIA_SAVE_SUCCESS';
export const DIGI_THEME_MEDIA_SAVE_ERROR = 'DIGI_THEME_MEDIA_SAVE_ERROR';
export const DIGI_THEME_MEDIA_READING = 'DIGI_THEME_MEDIA_READING';
export const DIGI_THEME_MEDIA_READ_SUCCESS = 'DIGI_THEME_MEDIA_READ_SUCCESS';
export const DIGI_THEME_MEDIA_READ_ERROR = 'DIGI_THEME_MEDIA_READ_ERROR';
export const DIGI_THEME_MEDIA_DELETING = 'DIGI_THEME_MEDIA_DELETING';
export const DIGI_THEME_MEDIA_DELETE_SUCCESS = 'DIGI_THEME_MEDIA_DELETE_SUCCESS';
export const DIGI_THEME_MEDIA_DELETE_ERROR = 'DIGI_THEME_MEDIA_DELETE_ERROR';
export const DIGI_THEME_MEDIA_RESET = 'DIGI_THEME_MEDIA_RESET';

// ------------------------------------
// Actions
// ------------------------------------
const saving = createAction(DIGI_THEME_MEDIA_SAVING);

const saveSuccess = createAction(DIGI_THEME_MEDIA_SAVE_SUCCESS, data => ({
    data
  }   
));

const saveFailed = createAction(DIGI_THEME_MEDIA_SAVE_ERROR, error => ({
    error
  }
));

const reading = createAction(DIGI_THEME_MEDIA_READING, objectUuid => ({ objectUuid }));

const readSuccess = createAction(DIGI_THEME_MEDIA_READ_SUCCESS, (records, total) => ({
    records,
    total
  }
));

const readFailed = createAction(DIGI_THEME_MEDIA_READ_ERROR, error => ({
    error
  }
));

// const deleting = () => ({ type: DIGI_THEME_MEDIA_DELETING });

// const deleteSuccess = () => ({ type: DIGI_THEME_MEDIA_DELETE_SUCCESS });

// const deleteFailed = error => ({
//   type: DIGI_THEME_MEDIA_DELETE_ERROR,
//   payload: {
//     error
//   }
// });

export const resetMedia = createAction(DIGI_THEME_MEDIA_RESET);

// ------------------------------------
// Action creators
// ------------------------------------
export const readMedia = (recordUuid, srid, themeUuid) => {
  return (dispatch, getState, getReducerState) => {
    dispatch(reading(recordUuid));
    request
      .post(
        window.AdaptiveLight.config.instanceUrl +
          'WebServices/generic/Media.asmx/Read'
      )
      .send({
        extraParams: [
          {
            key: 'owner_uuid',
            value: recordUuid
          },
          {
            key: 'parent_object_uuid',
            value: themeUuid
          }
        ],
        filter: {
          filterColumns: [],
          defaultFilter: [],
          secondFilter: []
        },
        limit: 100,
        page: 1,
        start: 0
      })
      .set('X-Adaptive-SRID', srid)
      .set('Accept', 'application/json')
      .then(res => res.body)
      .then(res => {
        res = res.d;
        if (!res.success) {
          dispatch(readFailed(res.exception.message));
        }

        dispatch(readSuccess(res.records, res.total));
      })
      .catch(e => {
        dispatch(readFailed(e || { message: 'readFailed' }));
      });
  };
};

// export const updateMedia = record => {
//   return dispatch => {
//     dispatch(saving());
//     request
//       .post(
//         window.AdaptiveLight.config.instanceUrl +
//           'WebServices/generic/Media.asmx/Update'
//       )
//       .send({ data: record, extraParams: [] })
//       .set('Accept', 'application/json')
//       .then(res => res.body)
//       .then(res => {
//         res = res.d;

//         if (!res.success) {
//           dispatch(saveFailed(res.exception.message));
//         }

//         dispatch(saveSuccess());
//       })
//       .catch(e => {
//         dispatch(saveFailed(e || { message: 'readFailed' }));
//       });
//   };
// };

// export const deleteMedia = mediaUuid => {
//   return (dispatch, getState, getReducerState) => {
//     dispatch(deleting());
//     // const themeUuid = getReducerState().themeUuid;
//     request
//       .post(
//         window.AdaptiveLight.config.instanceUrl +
//           'WebServices/generic/Media.asmx/Destroy'
//       )
//       .send({ uuid: mediaUuid, extraParams: [] })
//       .set('Accept', 'application/json')
//       .then(res => res.body)
//       .then(res => {
//         res = res.d;

//         if (!res.success) {
//           dispatch(deleteFailed(res.exception.message));
//         }

//         dispatch(deleteSuccess());
//       })
//       .catch(e => {
//         dispatch(deleteFailed(e || { message: 'readFailed' }));
//       });
//   };
// };

export const saveMedia = (files, recordUuid, srid, themeUuid) => {
  return dispatch => {
    dispatch(saving());

    create(dispatch, files, recordUuid, themeUuid, srid);
  };
};

const create = (dispatch, files, recordUuid, themeUuid, srid, iterator = 0) => {
  const mediaFile = files[iterator];
  const media = {
    name: mediaFile.name,
    mime: mediaFile.type,
    owner_uuid: recordUuid,
    parent_object_type: 'digi_theme',
    parent_object_uuid: themeUuid,
    config: { configs: [{ key: 'isPublic', value: true }] }
  };
  request
    // .post('http://localhost/a_a3/WebServices/dialog/Public.asmx/CreateMedia')
    .post(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/CreateMedia'
    )
    .send({ data: media, extraParams: [] })
    .set('X-Adaptive-SRID', srid)
    .set('Accept', 'application/json')
    .then(res => res.body)
    .then(res => {
      res = res.d;
      if (!res.success) {
        dispatch(saveFailed(res.exception.message));
      }

      const dataObj = fixKeyValueArraysToJsObject(res.data).find(
        r => r.type === 'Cartomatic.Utils.OpsIO.DataObj'
      );
      if (dataObj.uuid) {
        upload(
          dispatch,
          files,
          dataObj.uuid,
          recordUuid,
          themeUuid,
          srid,
          iterator
        );
      } else {
        dispatch(saveFailed('Something went wrong when saving the image'));
      }
    })
    .catch(e => {
      dispatch(saveFailed(e || { message: 'saveFailed' }));
    });
};

const upload = (
  dispatch,
  files,
  uuid,
  recordUuid,
  themeUuid,
  srid,
  iterator
) => {
  const url =
    window.AdaptiveLight.config.instanceUrl +
    'WebServices/dialog/Public.asmx/UploadMedia?' +
    'uuid=' +
    uuid;
  // const url =
  //   'http://localhost/a_a3/WebServices/dialog/Public.asmx/UploadMedia?uuid=' +
  //   uuid;
  const file = files[iterator];

  request
    .post(url)
    .attach('file', file)
    .set('Accept', 'application/json')
    .then(res => JSON.parse(res.text))
    .then(res => {
      if (!res.success) {
        dispatch(saveFailed(res.exception.message));
      }
      if (files.length - 1 !== iterator) {
        create(dispatch, files, recordUuid, themeUuid, srid, iterator + 1);
      } else {
        dispatch(saveSuccess());
      }
    })
    .catch(e => {
      dispatch(saveFailed(e || { message: 'saveFailed' }));
    });
};

export const actions = {
  readMedia,
  resetMedia,
  saveMedia
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  saving: false,
  deleting: false,
  loading: false,
  error: undefined,
  objectUuid: undefined,
  records: [],
  total: undefined
};

export default handleActions(
  {
      DIGI_THEME_MEDIA_SAVING: (state, { payload }) => {
        return Object.assign({}, state, {
          saving: true,
          error: undefined
        });
      },
      DIGI_THEME_MEDIA_SAVE_SUCCESS: (state, { payload }) => {
        return Object.assign({}, state, {
          saving: false
        });
      },
        DIGI_THEME_MEDIA_SAVE_ERROR: (state, { payload }) => {
        return Object.assign({}, state, {
          saving: false,
          error: payload.error
        });
      },
        DIGI_THEME_MEDIA_READING: (state, { payload }) => {
        return Object.assign({}, state, {
          objectUuid: payload.objectUuid,
          loading: true,
          records: [],
          error: undefined
        });
      },
        DIGI_THEME_MEDIA_READ_SUCCESS: (state, { payload }) => {
        return Object.assign({}, state, {
          loading: false,
          records: payload.records,
          total: payload.total
        });
      },
        DIGI_THEME_MEDIA_READ_ERROR: (state, { payload }) => {
        return Object.assign({}, state, {
          loading: false,
          error: payload.error
        });
      },
        DIGI_THEME_MEDIA_DELETING: (state, { payload }) => {
        return Object.assign({}, state, {
          deleting: true,
          error: undefined
        });
      },
        DIGI_THEME_MEDIA_DELETE_SUCCESS: (state, { payload }) => {
        return Object.assign({}, state, {
          deleting: false
        });
      },
      DIGI_THEME_MEDIA_DELETE_ERROR: (state, { payload }) => {
        return Object.assign({}, state, {
          deleting: false,
          error: payload.error
        });
      },
        DIGI_THEME_MEDIA_RESET: (state, { payload }) => {
        return Object.assign({}, state, initialState);
      },
    }, {
      initialState
    } 
);
