import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import SamfunnsDialog from '../containers/SamfunnsDialog';
import { withRouter } from 'react-router-dom';
import './DialogView.scss';
import './DialogFooterStyles.scss';
import { actions as dialogActions } from '../redux/modules/dialog/samfunnsdialogs';
import { actions as filterActions } from '../redux/modules/dialog/filter';
import { actions as menuButtonActions } from '../redux/modules/menuButton';
import { Button, Glyphicon } from 'react-bootstrap';
import MenuButton from '../components/Buttons/MenuButton';
import MediaQuery from 'react-responsive';
import FilterList from '../components/FilterList';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    Object.assign({}, dialogActions, menuButtonActions, filterActions),
    dispatch
  );

const mapStateToProps = state => ({
  expand: PropTypes.func.isRequired,
  expanded: state.menuButton.expanded,
  dialogs: state.samfunnsdialogs.dialogs,
  samfunnstopics: state.samfunnsdialogs.samfunnstopics,
  topicsFilter: state.filter.topicsFilter
});

export class SamfunnsDialogView extends React.Component {
  state = {
    displayFilter: false
  }

  constructor(props) {
    super(props);

    this.handleMediaQueryChange = this.handleMediaQueryChange.bind(this);
  }

  componentDidUpdate(prevProps) {   
    const instanceName = this.props.match.params.instanceName;
    if (this.props.samfunnstopics.length === 0 || prevProps.match.params.instanceName !== this.props.match.params.instanceName) {
      this.props.loadSamfunnsTopics(instanceName);
    }
    if (prevProps.samfunnstopics.length === 0 && this.props.samfunnstopics.length !== 0 || this.props.samfunnstopics.length > 0 && this.props.topicsFilter.length === 0) {
      let tema = this.props.samfunnstopics.map((topic) => {
        return {
          key: topic.codeid,
          value: topic.code,
          active: true
        }
      })
      this.props.setTopicsFilter(tema);     
    }
  }

  componentDidMount() {
    if (this.props.hideList && this.props.expanded) {
      this.props.expand();
    } 
  }

  handleMediaQueryChange(matches) {
    if (!matches && !this.props.expanded) {
      this.props.expand();
      this.props.setListVisibility(false);
    }
  } 

  render() {
    const {
      children,
      t,
      history,
      expanded,
      match,
      hideList,
      disableNavLinks
    } = this.props;

    const { displayFilter } = this.state;
    const dialogId = 0;
    const tema = this.props.topicsFilter;
    const instanceName = match.params ? match.params.instanceName : '';
    const baseUrl = '';
    const isMobileView = window.innerWidth < 768;
    return (
      <div>
        <MenuButton
          buttonText={t("ButtonHeaderText")}
          disableNavLinks={disableNavLinks}
          instanceName={instanceName}
          isSamfunnsDialog={true}
        />
        <div
          className={[
            'tool',
            'list',
            (!expanded && isMobileView) && 'toolHidden'
          ].join(' ')}
          id="dialogView-scrollPane"
        >
          <div className={'buttonBar'}>
            <Button
              bsStyle="primary"
              onClick={() => {
                let path = window.dialogPrefix + '/' + instanceName + '/ny';
                history.push(path);
              }}
            >
              <Glyphicon glyph="glyphicon glyphicon-pencil" />
              {' ' + t('createNewSuggestion')}
            </Button>
            <Button
              title={"Sett filter"}
              className={displayFilter ? 'btn-filter-active' : ''}
              bsStyle="primary"
              onClick={() => {
                this.setState({ displayFilter: !displayFilter });
              }}
            >
              <Glyphicon glyph="glyphicon glyphicon-filter" />
              {' '}
            </Button>
          </div>
         
          {tema && <FilterList 
            displayFilter={this.state.displayFilter}
            filterItemName={t('suggestions')} 
            filterItems={tema} 
            filterItemClicked={(clickedFilteredItem) => {
              var updatedTema = tema.map((fi) => {
                if (fi.key === clickedFilteredItem.key) {
                  fi.active = !fi.active;
                }
                return fi;
              });
              this.props.setTopicsFilter(updatedTema);
            }}  
          />}

          <div className="container-fluid">
            <SamfunnsDialog
              instanceName={instanceName}
              topicsFilter={tema}
              dialogId={parseInt(dialogId, 10)}
              readBaseUrl={baseUrl}
            />
          </div>
        </div>
        <MediaQuery query="(max-width: 768px)" onChange={this.handleMediaQueryChange}>
          {/* {!hideList && ( */}
            <div
              style={{ position: 'fixed' }}
              className={['footer', 'btn-group'].join(' ')}
              role="group"
              id="dialogView-footer"
            >
              {expanded && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.expand();
                    //this.props.setListVisibility(true);
                  }}
                >
                  Vis kart
                </button>
              )}
              {!expanded && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.expand();
                    //this.props.setListVisibility(false);
                  }}
                >
                  Vis liste
                </button>
              )}
            </div>
          {/* )} */}
        </MediaQuery>
        {children}
      </div>
    );
  }
}

SamfunnsDialogView.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.element,
  expanded: PropTypes.bool,
  dialogs: PropTypes.array,
  params: PropTypes.any,
  expand: PropTypes.func.isRequired,
  hideList: PropTypes.bool,
  disableNavLinks: PropTypes.bool,
  loadSamfunnsTopics: PropTypes.func.isRequired,
  setTopicsFilter: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('SamfunnsDialogView')(withRouter(SamfunnsDialogView)));
