import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions as mediaActions } from '../../redux/mediaReducer';
// import { bindActionCreators as bindScopedActionCreators } from '../../utils/scopedReducer';
import { bindActionCreators } from 'redux';

const mapDispatchToProps = dispatch => {
  return Object.assign(
    {},
    bindActionCreators(
      Object.assign({}, mediaActions),
      dispatch
    )
  );
};

const mapStateToProps = state => ({
  mediaSaving: state.mediaReducer.saving,
  mediaError: state.mediaReducer.error
});

class UploadButton extends React.Component {
  componentDidMount() {
    this.props.resetMedia();
  }

  componentWillUnmount() {
    this.props.resetMedia();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.mediaSaving &&
      !this.props.mediaSaving &&
      !this.props.mediaError
    ) {
      this.props.onSaveSuccess();
    }
  }

  initiateUpload(files, recordUuid) {
    if (files && files.length) {

      this.props.saveMedia(
        files,
        recordUuid,
        window.AdaptiveLight.config.mapProjCode,
        this.props.themeUuid
      );
    }
  }

  render() {
    const { recordUuid, labelText, keyName, mediaSaving } = this.props;
    return (
      <React.Fragment>
        <label
          htmlFor={keyName}
          className={
            mediaSaving ? 'btn btn-default disabled' : 'btn btn-default'
          }
        >
          {labelText}
        </label>
        <input
          type="file"
          id={keyName}
          onChange={evt => this.initiateUpload(evt.target.files, recordUuid)}
        />
      </React.Fragment>
    );
  }
}

UploadButton.propTypes = {
  resetMedia: PropTypes.func.isRequired,
  saveMedia: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  mediaSaving: PropTypes.bool,
  mediaError: PropTypes.object,
  recordUuid: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadButton);
