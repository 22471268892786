export default function initMap(instance) {
  // var uri = window.location.pathname.split('/');
  // var instance = uri.length > 1 && uri[1].toLowerCase();
  if (instance && window.AdaptiveLight.config.instances[instance]) {
    window.AdaptiveLight.config.map.view =
      window.AdaptiveLight.config.instances[instance];
    if (window.AdaptiveLight.config.map.view.defaultLanguage) {
      window.AdaptiveLight.config.defaultLanguage =
        window.AdaptiveLight.config.map.view.defaultLanguage;
    }
    if (window.AdaptiveLight.config.map.view.theme) {
      document.body.className = window.AdaptiveLight.config.map.view.theme;
    }
  }
}
