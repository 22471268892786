import React from 'react';
import { _searchSources } from '../../utils/dialog/dialog';
import PropTypes from 'prop-types';
import SearchField from './SearchField';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  ensureMap
} from 'react-openlayers';
import { actions as mapActions } from '../../redux/modules/map';

const mapDispatchToProps = dispatch => {
  return Object.assign(
    {},
    bindActionCreators(
      Object.assign({}, mapActions),
      dispatch
    )
  );
};

const mapStateToProps = state => ({
  mapState: state.map
});

export class SearchFieldInMap extends React.Component {
    static propTypes = {
      setCenter: PropTypes.func.isRequired,
      mapState: PropTypes.object,
      mapCenter: PropTypes.string
    }

    render () {
        const { map, setCenter } = this.props;
        return (
            <div className={'map-layout--search-field-container'}>
            <SearchField
              placeholder={'Søk'}
              map={map}
              fieldKey="person"
              sources={_searchSources}
              onResultSelected={(r) => {return;}}
              setCenter={(coords, zoom) => {
                if (coords && zoom) {
                   setCenter(coords, zoom);
                   var view = map.getView();
                   view.setCenter(coords);
                   view.setZoom(zoom);
                }
              }}
            />
          </div>
        )
    }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('DialogForm')(ensureMap(SearchFieldInMap)));