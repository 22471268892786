import { createAction, handleActions } from 'redux-actions';

// -------------------------------
// Constants
// -------------------------------

export const UPDATE_TOPICS_FILTER = 'UPDATE_TOPICS_FILTER';

// -------------------------------
// Actions
// -------------------------------

const updateTopicsFilter = createAction(UPDATE_TOPICS_FILTER, topicsFilter => ({ topicsFilter }));

export const setTopicsFilter = topicsFilter => {
  return (dispatch, getState) => {
    dispatch(updateTopicsFilter(topicsFilter));
  }
};

export const actions = {
  setTopicsFilter
};

// -------------------------------
// Reducer
// -------------------------------

export default handleActions(
  {
    UPDATE_TOPICS_FILTER: (state, { payload }) => {
      if (payload.topicsFilter) {
        return Object.assign({}, state, {
                topicsFilter: payload.topicsFilter
              });
      }
    },
  },
  {
    topicsFilter: [],
    detailsFilter: []
  }
);
