/** @format */
// import React from 'react';
// import vann from "../icons/water.svg";
// import { ReactComponent as Vann } from "../icons/water.svg";
import { getIcon } from './samfunnsdialogHelpers';
import { Style, Circle, Fill, Stroke, Icon } from "ol/style";

const getSvgIcon = (featureTopic, fillColor, opacity) => {
  featureTopic = featureTopic.toLowerCase();
  switch (featureTopic) {
		// case 'vann':
		// 	return <Vann fill={fillColor} opacity={opacity || 1}></Vann> 
		default:
			return null
	}
};

const getSvgIconUrl = (featureTopic) => {
  featureTopic = featureTopic.toLowerCase();
  switch (featureTopic) {
		// case 'vann':
		// 	return vann 
		default:
			return null
	}
};

const getFeatureStyle = (featureTopic, fillColor) => {
	const opacity = 0.7; //f.get("faded") ? 0.5 : 1;
	const zIndex = 99;
  featureTopic = featureTopic.toLowerCase();
  const svgIcon = getSvgIconUrl(featureTopic);
  if (svgIcon === null) {
    return new Style({
      image: new Circle({
        fill: new Fill({
          color: fillColor
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        }),
        radius: 10
      }),
      opacity: 1
    });
  }

  return new Style({
    image: new Icon({
      src: svgIcon,
      anchor: [0.5, 1],
      color: fillColor,
      opacity,
    }),
    zIndex,
  });
};

const getFeatureStylePng = (featureTopic) => {
	const zIndex = 99;
  featureTopic = featureTopic.toLowerCase();

  return new Style({
    image: new Icon({
      src: getIcon(featureTopic),
      anchor: [0.5, 1],
      scale: 0.2
      // size: [32, 32]
    }),
    zIndex,
  });
};

export { getFeatureStyle, getFeatureStylePng, getSvgIcon };
