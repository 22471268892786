import { combineReducers } from 'redux';
import { routeReducer as router } from 'redux-simple-router';
import language from './modules/language';
import map from './modules/map';
import dialogs from './modules/dialog/dialogs';
import samfunnsdialogs from './modules/dialog/samfunnsdialogs';
import samfunnsdialog from './modules/dialog/samfunnsdialog';
import filter from './modules/dialog/filter';
import dialog from './modules/dialog/dialog';
import toast from './modules/toast';
import menuButton from './modules/menuButton';
import mediaReducer from './mediaReducer';
import instanceReducer from './modules/dialog/instance';

export default combineReducers({
  language,
  map,
  router,
  dialogs,
  samfunnsdialogs,
  samfunnsdialog,
  dialog,
  filter,
  toast,
  instanceReducer,
  menuButton,
  mediaReducer
});
