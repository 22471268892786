import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import './DialogList.scss';
import Dot from './symbol/Dot';
import { getSvgIcon } from '../utils/icons';

export class DialogList extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dialogs: PropTypes.array.isRequired,
    onDialogOpen: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
  };

  handleMouseOver(dialog) {
    dialog.feature.set('hover', true);
  }

  handleMouseOut(dialog) {
    dialog.feature.set('hover', false);
  }

  formatDate(date) {
    const locale = this.props.t('locale');
    const opts = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    };
    return date.toLocaleString(locale, opts);
  }

  formatTime(date) {
    const locale = this.props.t('locale');
    const opts = {
      hour: 'numeric',
      minute: 'numeric'
    };
    return date.toLocaleString(locale, opts);
  }
  renderIcon(svgIcon, dialog) {
    if (svgIcon === null) {
      return <Dot color={dialog.fillColor} className={'circleIcon'} /> 
    }
    return svgIcon
  }

  renderDialog(dialog) {
    const { onDialogOpen } = this.props;
    const date = this.formatDate(dialog.date);
    const time = this.formatTime(dialog.date);

    const fillColor = dialog.feature.get('fillcolor') || "cyan";
    const svgIcon = getSvgIcon(dialog.topic, fillColor);
    return (
      <div
        key={dialog.id}
        className={['dialog-entry', 'clearfix', 'dialogContainer'].join(' ')}
        onClick={() => {
          dialog.feature.set('active', true);
          onDialogOpen(dialog);
        }}
        onMouseOver={() => {
          this.handleMouseOver(dialog);
        }}
        onMouseOut={() => {
          this.handleMouseOut(dialog);
        }}
      >
        <div className="caption">
          <div className={'table'}>
            <div className={'row'}>
              <div className={'status'}>
                {this.renderIcon(svgIcon, dialog)}
              </div>
              <div className={'datetime'}>
                <div dangerouslySetInnerHTML={{ __html: date }} />
                <div dangerouslySetInnerHTML={{ __html: time }} />
              </div>
              <div className={'topicaddress'}>
                <div className={'topic'}>
                  {dialog.topic} - {dialog.detail}
                </div>
                <div className={'adress'}>{dialog.obsadress}</div>
              </div>
            </div>

            {dialog.countpublicmessages > 0 && (
              <Glyphicon
                glyph="glyphicon glyphicon-comment"
                className={['pull-right', 'commentsGlyph'].join(' ')}
              >
                <span className={'comments'}>{dialog.countpublicmessages}</span>
              </Glyphicon>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t, isLoading, dialogs } = this.props;
    return (
      <div
        className={['clearfix', 'list', isLoading ? 'loading' : ''].join(' ')}
      >
        {!isLoading && !dialogs.length ? (
          <div className={'noResults'}>
            <p>{t('NoResults')}</p>
          </div>
        ) : (
          dialogs.map(dialog => this.renderDialog(dialog))
        )}
        <div className={'loadOverlay'}>
          <Glyphicon glyph="refresh" className="rotating" />
          {t('Loading')}
        </div>
      </div>
    );
  }
}

export default translate('DialogList')(DialogList);
