import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { applyMiddleware, compose, createStore } from 'redux';

export default function configureStore(initialState) {
  let createStoreWithMiddleware;
  const middleware = applyMiddleware(thunk);

  // if (window.__DEBUG__) {
  //   createStoreWithMiddleware = compose(
  //     middleware,
  //     window.devToolsExtension
  //       ? window.devToolsExtension()
  //       : require('..Dialog/containers/DevTools').default.instrument()
  //   );
  // } else {
  createStoreWithMiddleware = compose(middleware);
  // }

  const store = createStoreWithMiddleware(createStore)(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default;

      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
