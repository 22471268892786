import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import './AddToHomeScreen.scss';
import Logo from '../images/adaptivedialog.png';
import { translate } from 'react-translate';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import HomescreenLogo from '../images/samfunnsdialog_homescreen_logo.png';
import IosShareBar from '../images/samfunnsdialog_ios_share_bar.png';
import IosShareButton from '../images/samfunnsdialog_ios_add_to_homescreen.png';
import AndroidMenuButton from '../images/samfunnsdialog_android_menubutton.png';
import AndroidMenu from '../images/samfunnsdialog_android_menu.png';
import AndroidPopup from '../images/samfunnsdialog_android_popup.png';
import CloseImage from '../images/close.png';

const mapStateToPros = state => ({
  currentInstance: state.instanceReducer.instance
});

const AddToHomeScreenScreen = ({ onClose, t, currentInstance }) => {
  return (
    <div className="fullpage-screen">
      <div className="close-button" onClick={() => onClose()}>
        <img src={CloseImage} alt="Close about window"></img>
      </div>
      <div className="fullpage-screen--header">
      <div className='header-bar'>
        <Link to={window.dialogPrefix + '/' + currentInstance.friendlyname}>
          <img src={Logo} alt="adaptive dialog logo" />
          <h1>{t('AddToHomeScreen')}</h1>
        </Link>
      </div>   
      </div>
      <div className="fullpage-screen--content-container">
        <div className="content-box">
      <p>{t('AddToHomeScreenIntroText')}</p>
          
          <img src={HomescreenLogo} alt="adaptive samfunnsdialog homescreen app" />
          
  <h2>{t('AddToHomeScreenIosHeader')}</h2>
  <p>{t('AddToHomeScreenIosText1')}</p>
          <img src={IosShareBar} alt="adaptive samfunnsdialog ios share bar" />
  <p>{t('AddToHomeScreenIosText2')}</p>
          <img src={IosShareButton} alt="adaptive samfunnsdialog add to home screen button" />

  <h2>{t('AddToHomeScreenAndroidHeader')}</h2>
  <p>{t('AddToHomeScreenAndroidText1')}</p>
          <img src={AndroidMenuButton} alt="adaptive samfunnsdialog android chrome menu button" />

  <p>{t('AddToHomeScreenAndroidText2')}</p>

          <img src={AndroidMenu} alt="adaptive samfunnsdialog android chrome menu" />

          <img src={AndroidPopup} alt="adaptive samfunnsdialog android chrome popup" />
        </div>
      </div>

      <div className="about-screen--footer">
        <div className='test-wrapper'>
          {/* <div>1</div>
          <div>2</div> */}
            <div className='about-box'>
        <h2>{t('AboutTheSolution')}</h2>
        <p>{t('AboutPart1')}{' '}
              <a
                href="https://www.avinet.no/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Asplan Viak Internet AS
              </a>
              </p>
              <p>
              {t('AboutPart2')}
              </p>
              <p>{t('AboutPart3')}</p>
        <div className='cta-button'><a href="mailto:support@avinet.no">{t('ContactUs')}</a></div>
            </div>
            <div className='help-box'>
        <h2>{t('Problems')}?</h2>
              <p>{t('SendUsEmailAt')}{' '}
              <a href="mailto:support@avinet.no">support@avinet.no</a>  </p>
            </div>
            
          </div>
        </div>
    </div>
  );
};

export default connect(mapStateToPros)(translate('AboutSamfunnsDialogScreen')(AddToHomeScreenScreen));
