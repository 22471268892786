import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';
import { translate } from 'react-translate';
import './MouseOverPopup.scss';

const MouseOverPopup = (props) => {
    const style = {
        top: (props.y - 20) + 'px',
        left: props.x + 'px'
    }
    return <div className='mouse-over-popup' style={style} onClick={() => props.onPopupClick(props.id)}>
            <div className='topic-text'>
            {props.t('Suggestion')} #{props.id}
            </div>
            <div>
                {props.topic}
            </div>
            <div>
                {props.hasMedia && <Glyphicon glyph="camera" />}{props.hasComments && <Glyphicon glyph="comment" />}
            </div>
        </div>
}

MouseOverPopup.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    topic: PropTypes.string.isRequired,
    hasMedia: PropTypes.bool.isRequired,
    hasComments: PropTypes.bool.isRequired,
    onPopupClick: PropTypes.func
};

export default translate('MouseOverPopup')(MouseOverPopup);