import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
export const SET_LANGUAGE = 'SET_LANGUAGE';

// ------------------------------------
// Actions
// ------------------------------------
export const setLanguage = createAction(
  SET_LANGUAGE,
  (language = 'no') => language
);

export const actions = {
  setLanguage
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    SET_LANGUAGE: (state, { payload }) => payload
  },
  window.AdaptiveLight.config.defaultLanguage || 'no'
);
