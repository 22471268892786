import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { Button, Glyphicon } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { actions as menuButtonActions } from '../../redux/modules/menuButton';
import Modal from '../Modal';
import AboutForm from '../AboutForm';
import './MenuButton.scss';

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, menuButtonActions), dispatch);

const mapStateToProps = state => ({
  expanded: state.menuButton.expanded
});

export class MenuButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      showAboutDialog: false
    };

    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    this.setState({ showAboutDialog: true, showDropdown: false });
  }

  render() {
    const {
      t,
      expand,
      expanded,
      buttonText,
      disableNavLinks,
      instanceName,
      isSamfunnsDialog
    } = this.props;
    const { showDropdown, showAboutDialog } = this.state;
  
    const displayDropdown = showDropdown || (showDropdown && !expanded);
    return (
      <React.Fragment>
        <Button
          className={'menuButton'}
          bsStyle="primary"
          onClick={() => {
            // expand();
          }}
        >
          {buttonText || t('buttonText')}
          <div
            className="menuButton-menuLink"
            onClick={() =>
              this.setState({ showDropdown: !this.state.showDropdown })
            }
          >
            <Glyphicon
              glyph={'glyphicon glyphicon-menu-hamburger dropdown-toggle'}
            />
          </div>
        </Button>
        {displayDropdown && (
          <div className="menuButton-dropdown">
            <div
              onClick={() => {
                this.setState({ displayDropdown: false });
              }}
              className="menuButton-dropdown--list-item"
            >
              <Link to={window.dialogPrefix + '/' + instanceName + '/ny'}>
                {isSamfunnsDialog ? t('AddNewSuggestion') : t('AddNew')}
              </Link>
            </div>
            {expanded && !disableNavLinks && (
              <div
                onClick={() => {
                  expand();
                  this.setState({ displayDropdown: false });
                }}
                className="menuButton-dropdown--list-item hide-on-large-screens"
              >
                <a>{t('ShowMap')}</a>
              </div>
            )}
            {!expanded && !disableNavLinks && (
              <div
                onClick={() => {
                  expand();
                  this.setState({ displayDropdown: false });
                }}
                className="menuButton-dropdown--list-item hide-on-large-screens"
              >
                <a>{t('ShowList')}</a>
              </div>
            )}
             {isSamfunnsDialog &&  (<div
                onClick={() => {
                  this.setState({ displayDropdown: false });
                }}
                className="menuButton-dropdown--list-item hide-on-large-screens"
              >
                <Link to={window.dialogPrefix + '/' + instanceName + '/legg-til-hjemmeskjerm'}>
                  {t('AddToHomeScreen')}
                </Link>
              </div>
              )}
            <div
              onClick={() => {
                if (isSamfunnsDialog) {
                  this.props.history.push(
                    window.dialogPrefix + '/' + instanceName + '/om-samfunnsdialog'
                  )
                } else {
                  this.showModal()
                }         
            }}
              className="menuButton-dropdown--list-item"
            >
              <a>{isSamfunnsDialog ? t('AboutSamfunnsApp') : t('AboutApp')}</a>
            </div>
          </div>
        )}
        {showAboutDialog && (
          <Modal>
            <AboutForm
              onClose={() => this.setState({ showAboutDialog: false })}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

MenuButton.propTypes = {
  t: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  expand: PropTypes.func,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  disableNavLinks: PropTypes.bool,
  instanceName: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MenuButton')(withRouter(MenuButton)));
