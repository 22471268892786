import React from 'react';
import { connect } from 'react-redux';
import { Button, Glyphicon } from 'react-bootstrap';
import { translate } from 'react-translate';
// import instance from '../redux/modules/dialog/instance';
import { Link } from 'react-router-dom';
import './AboutSamfunnsDialogScreen.scss';
import Logo from '../images/adaptivedialog.png';
import SamfunnsDialogIllustration from '../images/samfunnsdialog_illustration.png';
import CloseImage from '../images/close.png';


const mapStateToPros = state => ({
  currentInstance: state.instanceReducer.instance
});

const AboutSamfunnsDialogScreen = ({ onClose, currentInstance, t }) => {
  console.log("currentInstance: ", currentInstance);

  return (
    <div className="about-screen">
      <div className="close-button" onClick={() => onClose()}>
        <img src={CloseImage} alt="Close about window"></img>
      </div>
      <div className="about-screen--header">
        <div className='header-bar'>
            <Link to={window.dialogPrefix + '/' + currentInstance.friendlyname}>
              <img src={Logo} alt="adaptive dialog logo" />
              <h1>{currentInstance && currentInstance.name ? currentInstance.name : 'Avinet'} Samfunnsdialog</h1>
            </Link>
          </div>
          
          <div className="header-content-container">     
          <div className='header-text'>
            <div>{t('DoYouHaveSuggestions')}
              {currentInstance && currentInstance.name ? ' ' + t('ForUs') +' ' + currentInstance.name : ''}?
            </div>
            <div>{t('SendUsYourSuggestion')}!</div>
          </div>
        </div>
        <div className="header-illustration"><img src={SamfunnsDialogIllustration} alt="Samfunnsdialog illustrasjon" /></div>
        
        
      </div>
      <div className="about-screen--content-container">
        <div className="content-box">
  <h1>{t('HowToUse')}</h1>
  <p>{t('HowToPart1')}</p>

  <p>{t('HowToPart2')} <Button className="pencil-button" bsStyle="primary"><Glyphicon glyph="glyphicon glyphicon-pencil" /></Button> {t('HowToPart3')}</p>
        </div>
      </div>

      <div className="about-screen--footer">
        <div className='test-wrapper'>
          {/* <div>1</div>
          <div>2</div> */}
            <div className='about-box'>
        <h2>{t('AboutTheSolution')}</h2>
        <p>{t('AboutPart1')}{' '}
              <a
                href="https://www.avinet.no/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Asplan Viak Internet AS
              </a>
              </p>
              <p>
              {t('AboutPart2')}
              </p>
              <p>{t('AboutPart3')}</p>
        <div className='cta-button'><a href="mailto:support@avinet.no">{t('ContactUs')}</a></div>
            </div>
            <div className='help-box'>
        <h2>{t('Problems')}?</h2>
              <p>{t('SendUsEmailAt')}{' '}
              <a href="mailto:support@avinet.no">support@avinet.no</a>  </p>
            </div>
            
          </div>
        </div>
    </div>
  );
};

export default connect(mapStateToPros)(translate('AboutSamfunnsDialogScreen')(AboutSamfunnsDialogScreen));
