import local from './DialogConfigLocal';
import demo from './DialogConfigDemo';
import prod from './DialogConfigProd';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
  window.AdaptiveLight = local;
} 
else if (hostname === 'm.adialog.no') {
  window.AdaptiveLight = prod;
}
// else if (
//   hostname === 'adaptive-dialog.firebaseapp.com' ||
//   hostname === 'demom.adialog.no'
// ) {
//   window.AdaptiveLight = demo;
// } 
else {
  window.AdaptiveLight = demo;
}
