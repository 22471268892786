import { createAction, handleActions } from 'redux-actions';
import {
  keyValueToObject,
  formatAdressTitle,
  getInstanceId,
  getMunicipality
} from '../../../utils/utils';
import { actions as mapActions } from '../map';
import { olUtils } from 'react-openlayers';
import { toast } from '../toast';
import * as routeActions from 'redux-simple-router';

// -------------------------------
// Constants
// -------------------------------

const emptyNewDialog = {
  id: undefined,
  uuid: undefined,
  saving: false,
  saveError: false,
  persphone: '',
  persname: '',
  persadress: {},
  email: '',
  feedback: '',
  obsadress: {},
  topic: '',
  detail: '',
  description: '',
  extraField1: '',
  extraField2: '',
  extraField3: '',
  extraField4: '',
  extraField5: ''
};

export const DIALOGS_LOADING = 'DIALOGS_LOADING';
export const DIALOGS_LOADED = 'DIALOGS_LOADED';

export const FEEDBACKS_LOADING = 'FEEDBACKS_LOADING';
export const FEEDBACKS_LOADED = 'FEEDBACKS_LOADED';

export const TOPICS_LOADING = 'TOPICS_LOADING';
export const TOPICS_LOADED = 'TOPICS_LOADED';

export const DETAILS_LOADING = 'DETAILS_LOADING';
export const DETAILS_LOADED = 'DETAILS_LOADED';

export const SET_EXTRA_FIELD1 = 'SET_EXTRA_FIELD1';
export const SET_EXTRA_FIELD2 = 'SET_EXTRA_FIELD2';
export const SET_EXTRA_FIELD3 = 'SET_EXTRA_FIELD3';
export const SET_EXTRA_FIELD4 = 'SET_EXTRA_FIELD4';
export const SET_EXTRA_FIELD5 = 'SET_EXTRA_FIELD5';

export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_NAME = 'SET_NAME';
export const SET_PERSADRESS = 'SET_PERSADRESS';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const SET_POI = 'SET_POI';
export const SET_TOPIC = 'SET_TOPIC';
export const SET_DETAIL = 'SET_DETAIL';
export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const CLEAR_NEWDIALOG = 'CLEAR_NEWDIALOG';

export const DIALOG_SAVING = 'DIALOG_SAVING';
export const DIALOG_SAVED = 'DIALOG_SAVED';
export const DIALOG_SAVE_FAILED = 'DIALOG_SAVE_FAILED';

// -------------------------------
// Actions
// -------------------------------

const dialogsLoading = createAction(DIALOGS_LOADING, () => ({}));
const dialogsLoaded = createAction(DIALOGS_LOADED, (dialogs, projCode) => ({
  dialogs,
  projCode
}));

const feedbacksLoading = createAction(FEEDBACKS_LOADING, () => ({}));
const feedbacksLoaded = createAction(FEEDBACKS_LOADED, feedbacks => ({
  feedbacks
}));

const topicsLoading = createAction(TOPICS_LOADING, () => ({}));
const topicsLoaded = createAction(TOPICS_LOADED, topics => ({ topics }));

const detailsLoading = createAction(DETAILS_LOADING, () => ({}));
const detailsLoaded = createAction(DETAILS_LOADED, details => ({ details }));

const phoneSearchLoading = createAction('PHONE_SEARCH_LOADING', () => ({}));
const phoneSearchLoaded = createAction('PHONE_SEARCH_LOADED', data => ({
  data
}));

const dialogSaving = createAction(DIALOG_SAVING, () => ({}));
const dialogSaved = createAction(DIALOG_SAVED, (dialog, projCode) => ({
  dialog,
  projCode
}));
const dialogSavedFailed = createAction(DIALOG_SAVE_FAILED, data => ({ data }));

export const setExtraField1 = createAction(SET_EXTRA_FIELD1, extraField1 => ({
  extraField1
}));
export const setExtraField2 = createAction(SET_EXTRA_FIELD2, extraField2 => ({
  extraField2
}));
export const setExtraField3 = createAction(SET_EXTRA_FIELD3, extraField3 => ({
  extraField3
}));
export const setExtraField4 = createAction(SET_EXTRA_FIELD4, extraField4 => ({
  extraField4
}));
export const setExtraField5 = createAction(SET_EXTRA_FIELD5, extraField5 => ({
  extraField5
}));

export const setPhoneNumber = createAction(SET_PHONE_NUMBER, persphone => ({
  persphone
}));
export const setName = createAction(SET_NAME, persname => ({ persname }));
export const setPersadress = createAction(SET_PERSADRESS, persadress => ({
  persadress
}));
export const setEmail = createAction(SET_EMAIL, email => ({ email }));
export const setPoi = createAction(SET_POI, obsadress => ({ obsadress }));
export const setFeedback = createAction(SET_FEEDBACK, feedback => ({
  feedback
}));
export const setTopic = createAction(SET_TOPIC, topic => ({ topic }));
export const setDetail = createAction(SET_DETAIL, detail => ({ detail }));
export const setDescription = createAction(SET_DESCRIPTION, description => ({
  description
}));
export const clearNewDialog = createAction(CLEAR_NEWDIALOG, () => ({}));

export const saveDialog = instanceName => {
  return (dispatch, getState) => {
    dispatch(dialogSaving());
    const state = getState();
    const newDialog = state.dialogs.newDialog;

    let params = {
      data: {
        persphone: newDialog.persphone,
        persname: newDialog.persname,
        persadress: undefined,
        persemail: newDialog.email,
        feedbacktype: newDialog.feedback,
        obsadress: undefined,
        topic: newDialog.topic,
        detail: newDialog.detail,
        instanceid: getInstanceId(instanceName),
        extraField1: newDialog.extraField1,
        extraField2: newDialog.extraField2,
        extraField3: newDialog.extraField3,
        extraField4: newDialog.extraField4,
        extraField5: newDialog.extraField5
      },
      comment: newDialog.description
    };

    if (newDialog.persadress) {
      params.data.persadress = formatAdressTitle(newDialog.persadress);
    }

    if (newDialog.obsadress && newDialog.obsadress.feature) {
      params.data.obsadress = formatAdressTitle(newDialog.obsadress);
      params.data.geom = olUtils.getWktFromFeature(newDialog.obsadress.feature);
    } else {
      if (params.data.persadress && newDialog.persadress.feature) {
        params.data.obsadress = params.data.persadress;
        params.data.geom = olUtils.getWktFromFeature(
          newDialog.persadress.feature
        );
      }
    }

    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/Save',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        if (res.success) {
          const data = keyValueToObject(res.data);

          // TODO Have to fix this with translate
          // var description =
          //   "Din henvendelse angående '{0}' har fått meldingsnummer #{1}";
          // description = description.replace('{0}', data.record.detail);
          // description = description.replace('{1}', data.record.id);

          if (data.feedback) {
            dispatch(toast('feedback', 'success', data.feedback || '', true));
          }

          // dispatch(
          //   toast('dialogSaved', 'success', description || undefined, true)
          // );
          if (data.record) {
            // dispatch(
            //   routeActions.pushPath('/' + instanceName + '/' + data.record.id)
            // );
            dispatch(loadDialogs(instanceName));
          } else {
            // dispatch(routeActions.pushPath('/' + instanceName));
          }
        } else {
          dispatch(
            toast('dialogSavedFailed', 'danger', res.exception.msg, true)
          );
          return;
        }
        dispatch(dialogSaved(res.success && res.data, state.map.projCode));
      })
      .catch(e => {
        dispatch(dialogSavedFailed(undefined));
        dispatch(toast('dialogSavedFailed', 'danger', undefined, true));
      });
  };
};

export const loadDialogs = (instanceName, filterString) => {
  return (dispatch, getState) => {
    dispatch(dialogsLoading());
    const state = getState();
    const filter = filterString || '';

    let params = {
      instanceid: getInstanceId(instanceName),
      municipality: getMunicipality(instanceName),
      filter
    };
    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadMessages',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        dispatch(dialogsLoaded(res.success && res.records, state.map.projCode));
      })
      .catch(e => dispatch(dialogsLoaded(undefined)));
  };
};

export const loadFeedbacks = instanceName => {
  return (dispatch, getState) => {
    dispatch(feedbacksLoading());

    let params = {
      instanceid: getInstanceId(instanceName),
      tablename: 'tbl_point',
      fieldname: 'feedbacktype',
      relcode: '',
      isPublic: true
    };

    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadCodes',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        dispatch(feedbacksLoaded(res.success && res.records));
      })
      .catch(e => dispatch(feedbacksLoaded(undefined)));
  };
};

export const loadTopics = instanceName => {
  return (dispatch, getState) => {
    dispatch(topicsLoading());
    let params = {
      instanceid: getInstanceId(instanceName),
      tablename: 'tbl_point',
      fieldname: 'topic',
      relcode: '',
      isPublic: true
    };
    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadCodes',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        dispatch(topicsLoaded(res.success && res.records));
      })
      .catch(e => dispatch(topicsLoaded(undefined)));
  };
};

export const loadDetails = (instanceName, topic) => {
  return (dispatch, getState) => {
    dispatch(detailsLoading());
    let params = {
      instanceid: getInstanceId(instanceName),
      tablename: 'tbl_point',
      fieldname: 'detail',
      relcode: topic,
      isPublic: true
    };
    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadCodes',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        dispatch(detailsLoaded(res.success && res.records));
      })
      .catch(e => dispatch(detailsLoaded(undefined)));
  };
};

export const searchAddressByPhoneNumber = (instanceName, persphone) => {
  return (dispatch, getState) => {
    dispatch(phoneSearchLoading());

    const state = getState();

    let params = {
      phoneNumber: persphone,
      instanceid: getInstanceId(instanceName)
    };
    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/SearchPhone',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        var dataObj = parseAddressByPhoneNumberResponse(
          res.success && res.data,
          state.map.projCode
        );
        if (
          res.success &&
          dataObj.persadress.feature &&
          !state.dialogs.newDialog.obsadress.feature
        ) {
          let padding = [0, 0, 0, 0];
          // If window is on right side, offset center
          if (window.innerWidth > 768) {
            padding[1] = 350;
          }
          const center = dataObj.persadress.feature
            .getGeometry()
            .getFirstCoordinate();

          dispatch(
            mapActions.setCenter(
              {
                center: center,
                padding
              },
              19
            )
          );
        }
        dispatch(phoneSearchLoaded(res.success && dataObj));
      })
      .catch(e => dispatch(detailsLoaded(undefined)));
  };
};

const parseAddressByPhoneNumberResponse = (data, projCode) => {
  const d = keyValueToObject(data);
  const adressObject = d.phonedata;
  const transformedCoordinates = olUtils.transformCoordinate(
    [adressObject.lon, adressObject.lat],
    'EPSG:4326',
    'EPSG:' + window.AdaptiveLight.config.mapProjCode
  );

  const wkt =
    'POINT(' +
    transformedCoordinates[0] +
    ' ' +
    transformedCoordinates[1] +
    ')';
  const feature = olUtils.createFeatureFromWkt(wkt);
  var a = adressObject.address.split(',');
  let persadress = {
    persname: adressObject.name,
    phonetype: adressObject.apparattype,
    persadress: {
      title: a[0] + ', ' + a[1].toUpperCase(),
      feature: feature,
      layerId: 'PhoneSearch'
    }
  };
  return persadress;
};

export const actions = {
  loadDialogs,
  setExtraField1,
  setExtraField2,
  setExtraField3,
  setExtraField4,
  setExtraField5,
  setPhoneNumber,
  setName,
  setPersadress,
  setEmail,
  loadFeedbacks,
  setFeedback,
  setPoi,
  setTopic,
  setDetail,
  setDescription,
  loadTopics,
  loadDetails,
  searchAddressByPhoneNumber,
  clearNewDialog,
  saveDialog
};

function createDate(dateString) {
  if (dateString.substring('/Date')) {
    dateString = dateString.replace('/Date(', '');
    dateString = dateString.replace(')/', '');
    var date = new Date(parseInt(dateString, 10));
    return date;
  }
  return new Date();
}

// -------------------------------
// Reducer
// -------------------------------

export default handleActions(
  {
    DIALOG_SAVING: state => {
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, {
          saving: false,
          saveError: false
        })
      });
    },

    DIALOG_SAVED: (state, { payload }) => {
      if (payload.dialog) {
        const data = keyValueToObject(payload.dialog);

        if (data.feedback) {
          return Object.assign({}, state, {
            newDialog: Object.assign({}, state.newDialog, {
              uuid: data.record.uuid,
              id: data.record.id,
              saving: false
            })
          });
        } else {
          return Object.assign({}, state, {
            newDialog: Object.assign({}, state.newDialog, {
              uuid: data.record.uuid,
              id: data.record.id,
              saving: false
            })
          });
        }
      } else {
        return Object.assign({}, state, {
          newDialog: Object.assign({}, state.newDialog, {
            saving: false,
            saveError: true
          })
        });
      }
    },

    DIALOG_SAVE_FAILED: (state, { payload }) => {
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, {
          saving: false,
          saveError: true
        })
      });
    },

    DIALOGS_LOADING: state => {
      return Object.assign({}, state, {
        loading: true
      });
    },

    DIALOGS_LOADED: (state, { payload }) => {
      if (payload.dialogs) {
        // const wktReader = new ol.format.WKT();
        let dialogs = payload.dialogs.map(data => {
          const {
            status,
            id,
            date_created,
            topic,
            detail,
            obsadress,
            countpublicmessages,
            geom
          } = data;
          // const f = wktReader.readFeature(geom);
          const f = olUtils.createFeatureFromWkt(geom);
          const date = createDate(date_created);

          return {
            status,
            id,
            date,
            topic,
            detail,
            obsadress,
            countpublicmessages,
            feature: f
          };
        });

        return Object.assign({}, state, {
          loading: false,
          dialogs: dialogs
        });
      } else {
        return Object.assign({}, state, {
          loading: false
        });
      }
    },

    FEEDBACKS_LOADING: state => {
      return Object.assign({}, state, {
        feedbacksLoading: true
      });
    },

    FEEDBACKS_LOADED: (state, { payload }) => {
      if (payload.feedbacks) {
        let feedbacks = payload.feedbacks.map(data => {
          const { code } = data;
          return {
            code
          };
        });

        return Object.assign({}, state, {
          feedbacksLoading: false,
          feedbacks: feedbacks
        });
      } else {
        return Object.assign({}, state, {
          feedbacksLoading: false
        });
      }
    },

    TOPICS_LOADING: state => {
      return Object.assign({}, state, {
        topicsLoading: true
      });
    },

    TOPICS_LOADED: (state, { payload }) => {
      if (payload.topics) {
        let topics = payload.topics.map(data => {
          const { code, codeid, tag } = data;
          let tags;
          if (tag) {
            tags = keyValueToObject(JSON.parse(tag));
          }

          return {
            code,
            codeid,
            tag: tags
          };
        });

        return Object.assign({}, state, {
          topicsLoading: false,
          topics: topics
        });
      } else {
        return Object.assign({}, state, {
          topicsLoading: false
        });
      }
    },

    DETAILS_LOADING: state => {
      return Object.assign({}, state, {
        detailsLoading: true
      });
    },

    DETAILS_LOADED: (state, { payload }) => {
      if (payload.details) {
        let details = payload.details.map(data => {
          const { code, codeid, tag } = data;
          let tags;
          if (tag) {
            tags = keyValueToObject(JSON.parse(tag));
          }

          return {
            code,
            codeid,
            tag: tags
          };
        });

        return Object.assign({}, state, {
          detailsLoading: false,
          details: details
        });
      } else {
        return Object.assign({}, state, {
          detailsLoading: false
        });
      }
    },

    PHONE_SEARCH_LOADING: state => {
      return Object.assign({}, state, {
        phoneSearchLoading: true
      });
    },

    PHONE_SEARCH_LOADED: (state, { payload }) => {
      if (payload.data) {
        return Object.assign({}, state, {
          phoneSearchLoading: false,
          newDialog: Object.assign({}, state.newDialog, payload.data)
        });
      } else {
        return Object.assign({}, state, {
          phoneSearchLoading: false
        });
      }
    },

    SET_PHONE_NUMBER: (state, { payload }) => {
      const { persphone } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { persphone })
      });
    },

    SET_EXTRA_FIELD1: (state, { payload }) => {
      const { extraField1 } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { extraField1 })
      });
    },

    SET_EXTRA_FIELD2: (state, { payload }) => {
      const { extraField2 } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { extraField2 })
      });
    },

    SET_EXTRA_FIELD3: (state, { payload }) => {
      const { extraField3 } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { extraField3 })
      });
    },

    SET_EXTRA_FIELD4: (state, { payload }) => {
      const { extraField4 } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { extraField4 })
      });
    },

    SET_EXTRA_FIELD5: (state, { payload }) => {
      const { extraField5 } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { extraField5 })
      });
    },

    SET_NAME: (state, { payload }) => {
      const { persname } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { persname })
      });
    },

    SET_PERSADRESS: (state, { payload }) => {
      let { persadress } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { persadress })
      });
    },

    SET_EMAIL: (state, { payload }) => {
      const { email } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { email })
      });
    },

    SET_FEEDBACK: (state, { payload }) => {
      const { feedback } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { feedback })
      });
    },

    SET_POI: (state, { payload }) => {
      const { obsadress } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { obsadress })
      });
    },

    SET_TOPIC: (state, { payload }) => {
      const { topic } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { topic, detail: '' })
      });
    },

    SET_DETAIL: (state, { payload }) => {
      const { detail } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { detail })
      });
    },

    CLEAR_NEWDIALOG: (state, { payload }) => {
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, emptyNewDialog)
      });
    },

    SET_DESCRIPTION: (state, { payload }) => {
      const { description } = payload;
      return Object.assign({}, state, {
        newDialog: Object.assign({}, state.newDialog, { description })
      });
    }
  },
  {
    loading: false,
    loadState: {},
    dialogs: [],
    topics: [],
    details: [],
    feedbacks: [],
    newDialog: emptyNewDialog
  }
);
