import React from 'react';
import PropTypes from 'prop-types';
import { olUtils, VectorLayer, provideMapState } from 'react-openlayers';
// import { actions as searchActions } from 'redux/modules/search/searchAgent';
import { translate } from 'react-translate';
import './SearchAgent.scss';
import { Button } from 'react-bootstrap';
import debounce from '../../utils/debounce';

export class SearchAgent extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    searchSource: PropTypes.object.isRequired,
    query: PropTypes.string.isRequired,
    map: PropTypes.object.isRequired,
    onResultSelected: PropTypes.func.isRequired,
    fieldKey: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    keepLayer: PropTypes.bool,
    setCenter: PropTypes.func.isRequired,
    mapExtent: PropTypes.array,
    projCode: PropTypes.string
  };

  state = {
    currentQuery: '',
    results: [],
    loading: false,
    visible: false
  };

  componentDidMount() {
    this.checkQuery(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.checkQuery(nextProps);
  }

  checkQuery = debounce(props => {
    const { query } = props;
    if (this.state.currentQuery !== query && query !== '') {
      this.performQuery(query);
    } else if (this.state.currentQuery !== '' && query === '') {
      this.clearSearch();
    }
  }, 500);

  performQuery(query) {
    this.setState({ currentQuery: query, loading: true });
    const { searchSource, mapExtent, projCode } = this.props;
    let bbox = {
      left: mapExtent[0],
      bottom: mapExtent[1],
      right: mapExtent[2],
      top: mapExtent[3]
    };

    let params = {
      request: {
        q: query + '*',
        srsid: projCode.split(':')[1],
        start: 0,
        limit: 100,
        bbox: bbox,
        layers: searchSource.dataIndex
      }
    };

    fetch(window.AdaptiveLight.search.url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(res => {
        res = res.d;
        const data = this.parseIndexerResponse(res.records);
        this.searchDone(data);
      })
      .catch(e => {
        console.warn('error', e);
        this.searchDone(null);
      });
  }

  parseIndexerResponse(records) {
    return records.map(rec => {
      const { desc, elementType, geom, layerId, layerName, title, uid } = rec;
      const feature = olUtils.createFeatureFromWkt(geom);
      return {
        uid: uid,
        title: title,
        description: desc,
        feature: feature,
        geom: geom,
        elementType: elementType,
        layerId: layerId,
        layerName: layerName
      };
    });
  }

  searchDone(data) {
    const results = {};
    data.forEach(d => {
      if (results[d.uid]) {
        return;
      }
      results[d.uid] = d;
    });

    this.setState({
      loading: false,
      results: results
    });
  }

  clearSearch() {
    this.setState({ currentQuery: '', loading: false, results: [] });
  }

  setMapCenter(coords) {
    const { setCenter } = this.props;
    const zoom = 19;
    let padding = [0, 0, 0, 0];
    // If window is on right side, offset center
    if (window.innerWidth > 768) {
      padding[1] = 350;
    }
    setCenter(coords, zoom);
  }

  render() {
    const {
      fieldKey,
      searchSource,
      t,
      onResultSelected,
      visible,
      keepLayer
    } = this.props;
    const { results, selected } = this.state;
    const numResults = Object.keys(results).length;
    return (
      <div className={'searchSource'}>
        {(keepLayer || visible) && (
          <VectorLayer
            key={searchSource.dataIndex}
            layerName={fieldKey + '_' + searchSource.dataIndex}
            features={Object.values(results).map(result => result.feature)}
            fillColor={searchSource.fillColor}
            fontColor={searchSource.fontColor}
          />
        )}
        {visible && (
          <ul className={numResults === 0 ? 'noResults' : ''}>
            <h2>
              {searchSource.layerName} ({numResults}){' '}
            </h2>
            {numResults > 5 && (
              <Button
                className="pull-right"
                bsStyle="link"
                title={t('showAllResults')}
                onClick={() =>
                  this.setState({ selected: !this.state.selected })
                }
              >
                {selected ? t('goBack') : t('showAllResults')}
              </Button>
            )}
            {Object.values(results || {})
              .slice(0, selected ? numResults : 5)
              .map(result => (
                <li
                  key={result.uid}
                  className="clearfix"
                  onClick={() => {
                    onResultSelected(result);
                    result.feature && result.feature.set('active', true);
                    result.feature &&
                      this.setMapCenter(
                        result.feature.getGeometry().getFirstCoordinate()
                      );
                  }}
                  onMouseOver={() =>
                    result.feature && result.feature.set('hover', true)
                  }
                  onMouseOut={() =>
                    result.feature && result.feature.set('hover', false)
                  }
                >
                  <h3>{result.title}</h3>
                  {result.description && (
                    <p className={'description'}>{result.description}</p>
                  )}
                </li>
              ))}
          </ul>
        )}
      </div>
    );
  }
}

export default translate('SearchAgent')(provideMapState(SearchAgent));
