export default {
  locale: 'no',

  Search: {
    Search: 'Søk...',
    Loading: 'Lastar...',
    Clear: 'Nullstill'
  },

  ArticleList: {
    ReadMore: 'Les meir',
    Loading: 'Lastar...'
  },

  ArticleWindow: {
    OpenExternal: 'Opne kjeldeartikkel i nytt vindauge',
    Close: 'Steng',
    Loading: 'Lastar artikkel...',
    LoadError: 'Kunne ikkje laste artikkel.'
  },

  ArticleComments: {
    locale: 'nn-NO',
    Comment: 'Skriv ein kommentar...',
    DisplayName: 'Visingsnamn',
    Email: 'E-post (ikkje synleg)',
    Submit: 'Publiser',
    CouldNotPostComment: 'Ein feil oppstod.',
    Publishing: 'Publiserer...',
    Hide: 'Gøym'
  },

  Comments: {
    locale: 'nb-NO',
    Previous: 'Forrige',
    Next: 'Neste',
    Page: 'Side',
    Of: 'av',
    Flag: 'Rapporter som upassande',
    Flagged: 'Rapportert'
  },

  SearchSource: {
    locale: 'nb-NO',
    showAllResults: 'Vis alle treff',
    goBack: 'Attende'
  },

  Dialog: {},

  DialogView: {
    createNew: 'Opprett ny melding',
    dialogs: 'Meldingar',
    latest: 'siste'
  },

  DialogList: {
    locale: 'nb-NO',
    comments: 'Se kommentar..',
    NoResults: 'Inga resultatar funne'
  },

  DialogMessages: {
    locale: 'nb-NO',
    date: 'Dato:',
    topic: 'Fag:',
    detail: 'Kode:',
    obsaddress: 'Obs.adresse:',
    status: 'Status:',
    comment: 'Kommentarar',
    message: 'Melding',
    saveSuccess: 'Melding lagret!',
    zoomIn: 'Zoom til melding',
    close: 'Lukk'
  },

  SamfunnsDialogSuggestion: {
    locale: 'nb-NO',
    date: 'Dato:',
    topic: 'Type:',
    status: 'Status:',
    comment: 'Kommentarar',
    message: 'innspel',
    saveSuccess: 'innspel lagret!',
    zoomIn: 'Zoom til innspel',
    close: 'Lukk',
    description: 'Beskrivelse'
  },

  SamfunnsDialogList: {
    locale: 'nb-NO',
    comments: 'Se kommentar..',
    NoResults: 'Inga resultatar funne',
    Loading: 'Laster data'
  },

  DialogForm: {
    reporter: 'Dine kontaktopplysningar',
    phonePlaceholder: 'Telefon',
    namePlaceholder: 'Namn',
    addressPlaceholder: 'Adresse',
    emailPlaceholder: 'E-post',
    topicPlaceholder: 'Vel tema',
    detailPlaceholder: 'Kva gjeld det',
    descriptionPlaceholder: 'Kommentar',
    place: 'Plassering og kommentar',
    save: 'Send inn',
    cancel: 'Avbryt',
    feedbackPlaceholder: 'Korleis ønskjer du å få tilbakemelding?',
    persAdressPlaceHolder: 'Di adresse',
    obsAdressPlaceHolder: 'Skriv adresse/stad eller marker i kartet',
    saveError: 'Kunne ikkje lagre, ver vennleg å prøv igjen',
    approveMessage:
      'Eg har lese personvernerklæringa, og godtar at eg ved å bruke denne tenesta godkjenner at data eg sender inn blir brukt i samsvar med ',
    privacyPolicy: 'personvernerklæringa',
    ThankYouForContributing: 'Takk for ditt bidrag',
    SavingImage: 'Lagrar bilete',
    AddImage: 'Legg til bilete'
  },

  SamfunnsDialogForm: {
    phonePlaceholder: 'Telefon',
    namePlaceholder: 'Namn',
    emailPlaceholder: 'E-post',
    topicPlaceholder: 'Vel tema',
    descriptionPlaceholder: 'Beskriv ditt innspel - teksten vil bli synlig offentlig i samfunnsdialog',
    save: 'Send inn',
    cancel: 'Avbryt',
    feedbackPlaceholder: 'Korleis ønskjer du å få tilbakemelding?',
    saveError: 'Kunne ikkje lagre, ver vennleg å prøv igjen',
    approveMessage:
      'Eg har lese personvernerklæringa, og godtar at eg ved å bruke denne tenesta godkjenner at data eg sender inn blir brukt i samsvar med ',
    privacyPolicy: 'personvernerklæringa',
    ThankYouForContributing: 'Takk for ditt bidrag',
    SavingImage: 'Lagrar bilete',
    AddImage: 'Legg til bilete',
    Receipt: 'Kvittering',
    ThanksForYourContribution: 'Takk for ditt innspel. Din henvendelse har fått innspelsnummer',
    YouCanAlsoUpload: ' Du kan også laste opp et bilde dersom du ønsker det. Bildet vil bli knytta til innspelet, og blir publisert offentlig.',
    Or: 'eller',
    SeeYourSuggestion: 'Se ditt innsendte innspel',
    BackTo: 'Tilbake til',
    AllSuggestions: 'alle innspel'
  },

  SearchAgent: {
    showAllResults: 'Vis alle treff',
    goBack: 'Tilbake'
  },

  MenuButton: {
    buttonText: 'Meny',
    ShowMap: 'Vis kart',
    ShowList: 'Vis liste',
    AboutApp: 'Om Adaptive Dialog',
    AboutSamfunnsApp: 'Om Samfunnsdialog',
    AddNew: 'Opprett ny melding',
    AddNewSuggestion: 'Opprett nytt innspel',
    AddToHomeScreen: 'Legg til på heimeskjerm'
  },

  DialogCreateNewView: {
    newDialog: 'Ny melding',
    CreateNewMessage: 'Opprett ny melding',
    PlaceInMap: 'Plasser meldinga di i kartet'
  },

  SamfunnsDialogCreateNewView: {
    newSuggestion: 'Nytt innspel',
    CreateNewSuggestion: 'Opprett nytt innspel',
    PlaceInMap: 'Plasser innspelet ditt i kartet'
  },

  ToastService: {
    dialogSaved: 'Ny melding mottatt',
    samfunnsDialogSaved: 'Nytt innspel mottatt',
    imageUploaded: 'Bilde lastet opp',
    dialogMessage: 'Din melding angående {0} har fått meldingsnummer #{1}',
    samfunnsDialogMessage: 'Ditt insnspill angående {0} har fått referanse nr #{1}',
    dialogSavedFailed: 'Manglande data',
    samfunnsDialogSavedFailed: 'Manglande data',
    feedback: 'Automatisk tilbakemelding'
  },

  MouseOverPopup: {
    Suggestion: 'Innspel'
  },

  MapLayout: {
    CreateNewMessage: "Opprett ny melding",
    CreateNewSuggestion: "Opprett nytt innspel"
  },
  
  SamfunnsDialogView: {
    ButtonHeaderText: "Samfunnsdialog - siste innspel",
    createNewSuggestion: 'Opprett nytt innspel',
    suggestions: 'Innspel',
  },

  AboutSamfunnsDialogScreen: {
    DoYouHaveSuggestions: 'Har du nokre gode idéar eller forslag',
    ForUs: 'til samfunnsplanlegginga i',
    SendUsYourSuggestion: 'Send oss ditt innspel',
    HowToUse: 'Slik bruker du samfunnsdialog',
    HowToPart1: 'Når du opnar løysninga ser du alle innsendte innspel frå våre innbyggarar. Du kan klikke på innspel, anten i kartet eller i lista for å sjå alle detaljane, og eventuelle bilde som vart sendt inn saman med innspelet. Du kan også lesa svaret frå oss i kommunen',
    HowToPart2: 'Om du ønskjer å sende inn ditt eige innspel kan du trykkje på',
    HowToPart3: 'knappen som du finn i lista og i kartet. I skjema skriv du inn detaljar i forslaget ditt, og om du eventuelt ønskjer ei attendemelding på e-post eller mobil. Du kan også leggje til bilde til innspelet ditt.  Attendemelding vert også publisert i samfunnsdialog.',
    AboutTheSolution: 'Om løysninga',
    AboutPart1: 'Samfunnsdialog er en del av Adaptive Dialog og vert levert av',
    AboutPart2: 'Adaptive Dialog er eit verktøy for kommunar til å handtere innspel og merknader få publikum. Innbyggjarane registrerer hendingar og forslag i enkle publikumsløysningar. Desse handterer  kommunen gjennom ei eiga handsamingsløysing. Der kan hendingar og forslag enkelt følgjast opp med innbyggjarane gjennom til dømes bruk av  SMS og e-post.',
    AboutPart3: 'Ønskjer du meir informasjon om Adaptive Dialog?',
    ContactUs: 'Kontakt oss',
    Problems: 'Problemer',
    SendUsEmailAt: 'Send oss en e-post på',
    AddToHomeScreen: 'Legg til på heimeskjerm',
    AddToHomeScreenIntroText: 'Adaptive Samfunnsdialog er tilpassa bruk på mobiltelefonar og nettbrett. For å få rask og enkel tilgang kan du leggje den til på heimeskjermen på telefonen din, då kan du starte den på same måte som andre applikasjonar på telefonen din.',
    AddToHomeScreenIosHeader: 'Vegleiing - iPhone/iPad',
    AddToHomeScreenIosText1: 'Opne samfunnsdialog i Safari-nettlesaren. Trykk på del-ikonet på verktøylina',
    AddToHomeScreenIosText2: 'Vel so "Legg til på hjem-skjerm", og skriv inn namn på snarvegen.',
    AddToHomeScreenAndroidHeader: 'Vegleiing - Android',
    AddToHomeScreenAndroidText1: 'Opne samfunnsdialog i Chrome-nettlesaren. Trykk på knappen oppe i høgre hjørne for å opne Chrome-menyen.',
    AddToHomeScreenAndroidText2: 'Vel so "Legg til på startsiden", og skriv inn namn på snarvegen.',
  }
};
