import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DialogCreateNew from '../containers/DialogCreateNew';
import { actions as dialogActions } from '../redux/modules/dialog/dialogs';
import { actions as menuButtonActions } from '../redux/modules/menuButton';
import * as routeActions from 'redux-simple-router';
import { translate } from 'react-translate';
import './DialogCreateNewView.scss';
import './DialogFooterStyles.scss';
import MenuButton from '../components/Buttons/MenuButton';
import MediaQuery from 'react-responsive';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    Object.assign({}, dialogActions, routeActions, menuButtonActions),
    dispatch
  );

const mapStateToPros = state => ({
  expanded: state.menuButton.expanded
});

export class DialogCreateNewView extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pushPath: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
    params: PropTypes.any,
    expand: PropTypes.func.isRequired
  };

  componentDidMount() {
    const isMobileView = window.innerWidth < 768;
    if (!isMobileView && !this.props.expanded) {
      this.props.expand();
    }
  }

  compinentWillReceiveProps(props) {}

  render() {
    const { t, expanded, match } = this.props;

    const instanceName = match.params ? match.params.instanceName : '';
    const baseUrl = '';

    return (
      <div>
        <MediaQuery query="(max-width: 768px)">
          <div className="new-dialog-helptext">{t('PlaceInMap')}</div>
        </MediaQuery>
        <MenuButton
          buttonText={t('CreateNewMessage')}
          disableNavLinks={true}
          instanceName={instanceName}
          isSamfunnsDialog={false}
        />
        <div
          className={['tool', !expanded && 'toolHidden'].join(' ')}
          // className={['tool'].join(' ')}
        >
          <div className="container-fluid">
            <DialogCreateNew
              instanceName={instanceName}
              readBaseUrl={baseUrl}
            />
          </div>
        </div>
        <MediaQuery query="(max-width: 768px)">
          <div
            style={{ position: 'fixed' }}
            className={['footer', 'btn-group'].join(' ')}
            role="group"
            id="dialogView-footer"
          >
            {expanded && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.props.expand();
                }}
              >
                Vis kart
              </button>
            )}
            {!expanded && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.props.expand();
                }}
              >
                Vis skjema
              </button>
            )}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate('DialogCreateNewView')(DialogCreateNewView));
