import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
export const SET_CENTER = 'SET_CENTER';
export const SET_ZOOM = 'SET_ZOOM';

// ------------------------------------
// Actions
// ------------------------------------
export const setCenter = createAction(SET_CENTER,
  (center, zoom = undefined, projCode = undefined, extent = undefined) => {
    var payload = {};
    if (center.padding) {
      payload.center = center.center;
      payload.padding = center.padding;
    } else {
      payload.center = center.center || center;
      payload.padding = [0, 0, 0, 0];
    }
    if (extent) payload.extent = extent;
    if (projCode) payload.projCode = projCode;
    if (zoom) payload.zoom = zoom;
    return payload;
  }
);
export const setZoom = createAction(SET_ZOOM, (zoom) => ({ zoom }));

export const actions = {
  setCenter,
  setZoom
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  SET_CENTER: (state, { payload }) => Object.assign({}, state, payload),
  SET_ZOOM: (state, { payload }) => Object.assign({}, state, payload)
}, {
  center: null,
  padding: [0, 0, 0, 0],
  extent: null,
  zoom: 7,
  projCode: 'EPSG:3857'
});
