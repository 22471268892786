import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-translate';
import { actions as dialogActions } from '../redux/modules/dialog/samfunnsdialogs';
import { actions as singleDialogActions } from '../redux/modules/dialog/samfunnsdialog';
import { VectorSource } from 'react-openlayers';
import SamfunnsDialogList from '../components/SamfunnsDialogList';
import * as routeActions from 'redux-simple-router';
import { posSamfunnsStatus } from '../utils/dialog/dialog';
import { actions as mapActions } from '../redux/modules/map';
import { getFeatureStylePng } from '../utils/icons';
import { ensureMap } from 'react-openlayers';
import MouseOverPopup from '../components/MouseOverPopup';

const initialPopupState = {
  x: undefined,
  y: undefined,
  id: undefined
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    Object.assign({}, dialogActions, singleDialogActions, mapActions, routeActions),
    dispatch
  );

const mapStateToProps = state => ({
  dialogs: state.samfunnsdialogs.samfunnsdialogs,
  dialogsLoadState: state.samfunnsdialogs.loadState,
  dialogsLoading: state.samfunnsdialogs.loading,
  mapCenter: state.map.center,
  mapZoom: state.map.zoom,
  projCode: state.map.projCode
});

export class SamfunnsDialog extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loadSamfunnsDialogs: PropTypes.func.isRequired,
    dialogs: PropTypes.array,
    dialogsLoadState: PropTypes.object,
    dialogsLoading: PropTypes.bool,
    setSamfunnsDialogRefreshNeeded: PropTypes.func.isRequired,
    mapCenter: PropTypes.any,
    mapZoom: PropTypes.number,
    projCode: PropTypes.string,
    history: PropTypes.object.isRequired,
    readBaseUrl: PropTypes.string.isRequired,
    setCenter: PropTypes.func,
    instanceName: PropTypes.string,
    dialogId: PropTypes.number,
    topicsFilter: PropTypes.array
  };

  state = {
    currentFilterString: "",
    mouseOverInitiated: false,
    popupState: initialPopupState
  }

  constructor() {
    super();

    this.moveStartHandler = this.moveStartHandler.bind(this);
    this.pointerMoveHandler = this.pointerMoveHandler.bind(this);
  }

  componentDidMount() {
    const { instanceName } = this.props;
    this.props.loadSamfunnsDialogs(instanceName);
    const { map: theMap } = this.props;
    theMap.on('movestart', this.moveStartHandler);
    theMap.on('pointermove', this.pointerMoveHandler);
  }

  componentDidUpdate() {
    if (this.props.topicsFilter.length > 0) {
        var topicsToFilter = this.props.topicsFilter.filter((t) => {if (!t.active) {return true} return false;});
        const filterString = topicsToFilter.map(function(t) { return t.key }).join(";");
        if (filterString !== this.state.currentFilterString) {
          this.setState({ currentFilterString: filterString });
          this.props.loadSamfunnsDialogs(this.props.instanceName, filterString);
        }
    }
  }

  moveStartHandler() {
    //Remove popup on map move, otherwise it looks misplaced
    const { map: theMap } = this.props;
    this.setState({ popupState: initialPopupState, mouseOverInitiated: false });
    
  }

  pointerMoveHandler(event) { //show a popup on mouseover
    const { map: theMap } = this.props;
    theMap.forEachFeatureAtPixel(event.pixel, (feature) => {
      const { popupState: oldPopupState } = this.state;
      if (feature.values_.id !== oldPopupState.id) {
        let popupState = Object.assign({}, oldPopupState, {
          x: event.pixel[0], 
          y: event.pixel[1], 
          id: feature.values_.id, 
          topic: feature.values_.topic, 
          uuid: feature.values_.uuid,
          hasComments: feature.values_.hasComments,
          hasMedia: feature.values_.hasMedia
        });
        this.setState({ popupState });
      }         
    })
  }

  get statusFeatures() {
    const { dialogs, history, instanceName, dialogId } = this.props;
    let statuses = {};
    posSamfunnsStatus.forEach(treatment => {
      statuses[treatment.dataIndex] = Object.assign(treatment, { features: [] });
    });
    
    dialogs.forEach(dialog => {
      if (dialogId === dialog.id) {
        dialog.feature.set('active', true);
      } else {
        dialog.feature.set('active', false);
      }
      dialog.feature.set('id', dialog.id);
      dialog.feature.set('topic', dialog.topic);
      dialog.feature.set('uuid', dialog.uuid);
      dialog.feature.set('hasMedia', dialog.countmedia > 0);
      dialog.feature.set('hasComments', dialog.countpublicmessages > 1);
      const style = getFeatureStylePng(dialog.topic);

      dialog.feature.set('fillcolor', statuses[dialog.treatment].fillColor);
      dialog.feature.setStyle(style);
      if (statuses[dialog.treatment]) {
        dialog.feature.onClick = () => {
          history.push(
            window.dialogPrefix + '/' + instanceName + '/' + dialog.id
          );
        };
        statuses[dialog.treatment].features.push(dialog.feature);
        dialog['fillColor'] = statuses[dialog.treatment].fillColor;
        dialog['strokeColor'] = statuses[dialog.treatment].strokeColor;
        dialog['fillOpacity'] = statuses[dialog.treatment].fillOpacity;
        dialog['strokeOpacity'] = statuses[dialog.treatment].strokeOpacity;
        dialog['strokeWidth'] = statuses[dialog.treatment].strokeWidth;
      }
    });
    return statuses;
  }

  render() {
    const { dialogs, history, instanceName, map } = this.props;
    const { popupState } = this.state; 
    if (!dialogs) {
      return null;
    }
    
    const statuses = this.statusFeatures;
    return (
      <div>
        {popupState.id && <MouseOverPopup 
          x={popupState.x} 
          y={popupState.y} 
          id={popupState.id}
          topic={popupState.topic}
          hasMedia={popupState.hasMedia}
          hasComments={popupState.hasComments}
          onPopupClick={(id) => history.push(window.dialogPrefix + '/' + instanceName + '/' + id) }
        />}
        {Object.values(statuses).map(status => {
          return (
            <VectorSource
              key={status.dataIndex}
              layerName={status.dataIndex}
              features={status.features}
            />
          );
        })}
        <SamfunnsDialogList
          dialogs={dialogs}
          onDialogOpen={dialog => {
            this.props.setSamfunnsDialogRefreshNeeded();
            history.push(
              window.dialogPrefix + '/' + instanceName + '/' + dialog.id
            );
            dialog.feature.set('active', true);
            let padding = [0, 0, 0, 0];
            if (window.innerWidth > 768) {
              padding[1] = 350;
            }
          }}
          isLoading={this.props.dialogsLoading}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('Samfunns Dialog')(withRouter(ensureMap(SamfunnsDialog))));
