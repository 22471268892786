import { createAction, handleActions } from 'redux-actions';
import { keyValueToObject } from '../../../utils/utils';
import { getSamfunnsStatus } from '../../../utils/dialog/dialog';
import { actions as mapActions } from '../map';
import { olUtils } from 'react-openlayers';

// ------------------------------
// Constants
// ------------------------------
export const SAMFUNNSDIALOG_LOADING = 'SAMFUNNSDIALOG_LOADING';
export const SAMFUNNSDIALOG_LOADED = 'SAMFUNNSDIALOG_LOADED';
export const SET_SAMFUNNSDIALOG_REFRESH = 'SET_SAMFUNNSDIALOG_REFRESH';

// ------------------------------
// Actions
// ------------------------------
export const dialogLoaded = createAction(
  SAMFUNNSDIALOG_LOADED,
  (id, dialog, projCode) => ({ id, dialog, projCode })
);
const dialogLoading = createAction(SAMFUNNSDIALOG_LOADING, id => ({ id }));

const setSamfunnsDialogRefreshNeeded = createAction(SET_SAMFUNNSDIALOG_REFRESH, () => ({}));

export const loadDialog = dialogId => {
  return (dispatch, getState) => {
    dispatch(dialogLoading(dialogId));
    const state = getState();
    let params = {
      pointId: dialogId
    };

    fetch(
      window.AdaptiveLight.config.instanceUrl +
        'WebServices/dialog/Public.asmx/ReadSamfunnsSuggestionAndLogs',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(params)
      }
    )
      .then(res => res.json())
      .then(res => {
        res = res.d;
        const dialogObj = parseDialog(res.success && res.data);
        if (res.success && dialogObj.feature) {
          let padding = [0, 0, 0, 0];
          // If window is on right side, offset center
          if (window.innerWidth > 768) {
            padding[1] = 350;
          }
          dispatch(
            mapActions.setCenter(
              {
                center: dialogObj.feature.getGeometry().getFirstCoordinate(),
                padding
              },
              state.map.zoom
            )
          );
        }
        dispatch(
          dialogLoaded(dialogId, res.success && dialogObj, state.map.projCode)
        );
      })
      .catch(e => dispatch(dialogLoaded(undefined)));
  };
};

export const actions = {
  loadDialog,
  setSamfunnsDialogRefreshNeeded
};

const parseDialog = data => {
  if (!data || data.length < 1) {
    return undefined;
  }
  const record = data[0].value
  const {
    status,
    id,
    uuid,
    treatment,
    date_modified,
    topic,
    geom
  } = record;
  var rawLogs = record.logs;
  if (rawLogs && rawLogs.length > 0) {
      rawLogs.forEach(function(l) {
      l.id = l.logid;
      l.date = parseDate(l.date_created);
    });
  }
  rawLogs.sort((a, b) => {
    return a.id - b.id;
  })

  const f = olUtils.createFeatureFromWkt(geom);
  const parsedDialog = {
    id,
    uuid,
    status,
    treatment,
    date: parseDate(date_modified),
    topic,
    feature: f,
    logs: rawLogs,
    fillColor: getSamfunnsStatus(treatment).fillColor
  };
  return parsedDialog;
};

const parseDate = date => {
  try {
    return new Date(parseInt(date.match(/.Date\((\d+)\)./)[1], 10));
  } catch (ex) {
    return null;
  }
};

// --------------------------
// Reducer
// --------------------------
export default handleActions(
  {
    SAMFUNNSDIALOG_LOADED: (state, { payload }) => {
      if (payload.dialog) {
        return { ...state, loading: false, id: payload.id, dialog: payload.dialog }
      } else {
        return Object.assign({}, state, {
          loading: false,
          dialog: undefined,
          samfunnsDialogRefreshNeeded: false
        });
      }
    },

    SAMFUNNSDIALOG_LOADING: (state, { payload }) => {
      return Object.assign({}, state, {
        loading: true,
        id: payload.id,
        samfunnsDialogRefreshNeeded: false
      });
    },
    SET_SAMFUNNSDIALOG_REFRESH: (state, { payload }) => {
      return Object.assign({}, state, {
        samfunnsDialogRefreshNeeded: true
      });
    }
  },
  {
    loading: false,
    id: undefined,
    dialog: undefined,
    samfunnsDialogRefreshNeeded: false
  }
);
