import React from 'react';
import PropTypes from 'prop-types';
// import NavBar from '../containers/NavBar';
import { Map, WmsLayer, MapPadding, Zoom, LayerSwitch, LayerGroup, } from 'react-openlayers';
import { translate } from 'react-translate';
import { provideViewSize } from '../hocs/provideViewSize';
import { withRouter } from 'react-router-dom';
import Title from '../components/Title';
import Icon from '../components/Icon';
import Modal from '../components/Modal';
import AboutForm from '../components/AboutForm';
import AboutSamfunnsDialogScreen from '../components/AboutSamfunnsDialogScreen';
import SearchFieldInMap from '../components/search/SearchFieldInMap';
import { Glyphicon } from 'react-bootstrap';
import Logo from '../images/adaptivedialog.png';
import './MapLayout.scss';

const Header = () => {
  return (
    <div className="map-layout--page-header">
      <div className="logo">
        <img src={Logo} alt="adaptive dialog logo" />
      </div>
  <div className="heading">{window.AdaptiveLight.config.appName}</div>
    </div>
  );
};

const convertToCoordinateArray = center => {
  var strArray = center.split(';');
  return [
    Number.parseFloat(strArray[0].trim()),
    Number.parseFloat(strArray[1].trim())
  ];
};

class MapLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoBox: false,
      center:
        window.innerWidth > 768
          ? [this.props.viewWidth * 1000, 8500000]
          : [2000000, 8650000],
      zoom: window.innerWidth > 768 ? 4 : 4
    };
    this.onSearchInit = this.onSearchInit.bind(this);
  }

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [68, 0, viewHeight / 4, 0];
    }
    return [68, 0, viewHeight / 4, 0];
  }

  onSearchInit(reducer) {
  }

  render() {
    const { children } = this.props;
    const { center, zoom } = window.AdaptiveLight.config.map.view;
    const {showInfoBox} = this.state;
    var centerCoordinates = center;

    if (centerCoordinates && typeof centerCoordinates === 'string') {
      centerCoordinates = convertToCoordinateArray(centerCoordinates);
    }

    var isSamfunnsDialog = false;

    var instanceName = window.location.pathname.substring(1).split('/')[0];
    if (instanceName === 'dialog') {
      window.dialogPrefix = '/dialog';
      instanceName = window.location.pathname.substring(1).split('/')[1];
    }
    if (instanceName === 'samfunnsdialog') {
      isSamfunnsDialog = true;
      window.dialogPrefix = '/samfunnsdialog';
      instanceName = window.location.pathname.substring(1).split('/')[1];
      window.AdaptiveLight.config.appName = "Adaptive Samfunnsdialog"
    }

    return (
      <div
        className={
          window.displayDialogHeader
            ? 'map-layout--root show-header'
            : 'map-layout--root'
        }
      >
        {window.displayDialogHeader && <Header />}
        <Title
          title={
            this.props.title
          }
        />
        <Map
          center={centerCoordinates}
          zoom={zoom}
          minZoom={window.AdaptiveLight.config.minZoom}
          maxZoom={window.AdaptiveLight.config.maxZoom}
          track
          baatUrl={window.AdaptiveLight.config.instanceUrl}
          trackTipLabel={"Zoom til din lokasjon"}
        >
          <Zoom
            zoomInTipText={"Zoom inn"}
            zoomOutTipText={"Zoom ut"} 
          />
          <MapPadding padding={this.calcMapPadding()} />
          <LayerSwitch tooltip={'Velg bakgrunnskart'}>
            <LayerGroup
              id="basemap_group"
              enabledLayers={['norgeskart_topo4']}
              name={'Bakgrunnskart'}
              activeIcon={<Icon name="radioChecked" />}
              inactiveIcon={<Icon name="radioUnchecked" />}
              singleSelect
            >
              <WmsLayer
                key="norgeskart"
                name="Norgeskart"
                uri={[
                  'https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.cache',
                  'https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache',
                  'https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.cache'
                ]}
                id="norgeskart_topo4"
                layerName="topo4"
                version="1.1.1"
                zIndex={-3}
                useBaat
              />
              <WmsLayer
              id="Norge_i_bilder"
              key="Norge_i_bilder"
              name="Norge i bilder"
              uri={[
                'https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_v2',
                'https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_v2',
                'https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.nib_web_mercator_v2'
              ]}
              version="1.3.0"
              layerName="0"
              zIndex={-2}
              useBaat
            />
            </LayerGroup>
          </LayerSwitch>

          <div className="root-container">{children}</div>
          <div className="map-layout-buttons-container">
            <button
              title={"Hjelp"}
              className="button"
              onClick={() => {
                if (isSamfunnsDialog) {
                  this.props.history.push(
                    window.dialogPrefix + '/' + instanceName + '/' + (isSamfunnsDialog ? 'om-samfunnsdialog' : 'om-dialog')
                  )
                } else {
                  this.setState({ showInfoBox: true });
                }
              }
              }
            >
              <Icon name="info" />
            </button>
            <button
              title={isSamfunnsDialog ? this.props.t("CreateNewSuggestion") : this.props.t("CreateNewMessage")}
              className="button"
              onClick={() => {              
                let path = window.dialogPrefix + '/' + instanceName + '/ny';
                this.props.history.push(path);
              }
              }
            >
              <Glyphicon glyph="glyphicon glyphicon-pencil" />
            </button>
          </div>
          {showInfoBox && !isSamfunnsDialog && (
            <Modal>
              <AboutForm
                onClose={() => this.setState({ showInfoBox: false })}
              />
            </Modal>
          )}
          <SearchFieldInMap></SearchFieldInMap>
        </Map>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.element,
  viewWidth: PropTypes.number.isRequired,
  viewHeight: PropTypes.number.isRequired
};

export default provideViewSize(withRouter(translate('MapLayout')(MapLayout)));
