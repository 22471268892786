import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import './Toast.scss';

class Toast extends React.Component {
  render() {
    const { show, message, type, onAlertDismiss } = this.props;
    return (
      <Alert
        className={'toast ' + (show ? 'show' : 'hide')}
        bsStyle={type}
        onDismiss={onAlertDismiss || false}
      >
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Alert>
    );
  }
}

Toast.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onAlertDismiss: PropTypes.func
};

export default Toast;
