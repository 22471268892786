import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, FormControl, FormGroup, Glyphicon } from 'react-bootstrap';
import { translate } from 'react-translate';
import { actions as dialogActions } from '../redux/modules/dialog/dialogs';
import { actions as toastActions } from '../redux/modules/toast';
import { actions as menuButtonActions } from '../redux/modules/menuButton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UploadButton from './Buttons/UploadButton';
import SearchField from '../components/search/SearchField';
import {
  WmsLayer,
  DrawLayer,
  olUtils,
  provideMapState
} from 'react-openlayers';
import { _searchSources } from '../utils/dialog/dialog';
import { formatAdressTitle } from '../utils/utils';
import Icon from './Icon';
import './DialogForm.scss';

const initialState = {
  approvePrivacyPolicy: false,
  notifyPrivacyPolicy: false,
  savedDialogId: undefined,
  savedDialogUuid: undefined
};
const mapDispatchToProps = dispatch => {
  return Object.assign(
    {},
    bindActionCreators(
      Object.assign({}, dialogActions, menuButtonActions, toastActions),
      dispatch
    )
  );
};

const mapStateToProps = state => ({
  newDialog: state.dialogs.newDialog,
  topics: state.dialogs.topics,
  details: state.dialogs.details,
  feedbacks: state.dialogs.feedbacks,
  mediaSaving: state.mediaReducer.saving,
  mediaError: state.mediaReducer.error,
  map: state.map,
  mapCenter: state.map.center,
  expanded: state.menuButton.expanded
});

export class DialogForm extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    setExtraField1: PropTypes.func.isRequired,
    setExtraField2: PropTypes.func.isRequired,
    setExtraField3: PropTypes.func.isRequired,
    setExtraField4: PropTypes.func.isRequired,
    setExtraField5: PropTypes.func.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setPersadress: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setPoi: PropTypes.func.isRequired,
    setTopic: PropTypes.func.isRequired,
    setDetail: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    topics: PropTypes.array,
    loadTopics: PropTypes.func.isRequired,
    details: PropTypes.array,
    loadDetails: PropTypes.func.isRequired,
    searchAddressByPhoneNumber: PropTypes.func.isRequired,
    map: PropTypes.object,
    loadFeedbacks: PropTypes.func.isRequired,
    setFeedback: PropTypes.func.isRequired,
    feedbacks: PropTypes.array,
    newDialog: PropTypes.object,
    setCenter: PropTypes.func.isRequired,
    clearNewDialog: PropTypes.func.isRequired,
    setWarning: PropTypes.func.isRequired,
    expand: PropTypes.func.isRequired,
    instanceName: PropTypes.string,
    toast: PropTypes.func.isRequired,
    mediaSaving: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = initialState;

    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    const { instanceName } = this.props;
    this.props.loadTopics(instanceName);
    this.props.loadFeedbacks(instanceName);
    const isMobileView = window.innerWidth < 768;
    if (isMobileView && this.props.expanded) {
      this.props.expand()
    }
  }

  componentWillUnmount() {
    this.props.clearNewDialog();
    this.resetState();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.newDialog.id === undefined &&
      this.props.newDialog.id !== undefined
    ) {
      this.setState({
        savedDialogId: this.props.newDialog.id,
        savedDialogUuid: this.props.newDialog.uuid
      });
    }
    if (prevProps.mapCenter !== this.props.mapCenter) {
      this.props.setCenter(this.props.mapCenter);
    }
  }

  resetState() {
    this.setState(initialState);
  }

  onPhoneNumberChange(phoneNumber) {
    const {
      setPhoneNumber,
      searchAddressByPhoneNumber,
      instanceName
    } = this.props;

    if (phoneNumber.length === 8) {
      searchAddressByPhoneNumber(instanceName, phoneNumber);
    }
    setPhoneNumber(phoneNumber);
  }

  getTopicObject(topic) {
    const { topics } = this.props;
    let t;

    t = topics.find(o => {
      return o.code === topic;
    });
    return t;
  }

  getDetailObject(detail) {
    const { details } = this.props;
    let t;

    t = details.find(o => {
      return o.code === detail;
    });
    return t;
  }

  getExtraFields(fields) {
    const {
      setExtraField1,
      setExtraField2,
      setExtraField3,
      setExtraField4,
      setExtraField5
    } = this.props;
    var fieldrows = [];
    for (var i = 0, len = fields.length; i < len; i++) {
      if (fields[i].value === 'extra1') {
        fieldrows.push(
          <FormGroup key={fields[i].text}>
            <FormControl
              type="text"
              placeholder={fields[i].text}
              onChange={e => {
                setExtraField1(e.target.value);
              }}
            />
          </FormGroup>
        );
      } else if (fields[i].value === 'extra2') {
        fieldrows.push(
          <FormGroup key={fields[i].text}>
            <FormControl
              type="text"
              placeholder={fields[i].text}
              onChange={e => {
                setExtraField2(e.target.value);
              }}
            />
          </FormGroup>
        );
      } else if (fields[i].value === 'extra3') {
        fieldrows.push(
          <FormGroup key={fields[i].text}>
            <FormControl
              type="text"
              placeholder={fields[i].text}
              onChange={e => {
                setExtraField3(e.target.value);
              }}
            />
          </FormGroup>
        );
      } else if (fields[i].value === 'extra4') {
        fieldrows.push(
          <FormGroup key={fields[i].text}>
            <FormControl
              type="text"
              placeholder={fields[i].text}
              onChange={e => {
                setExtraField4(e.target.value);
              }}
            />
          </FormGroup>
        );
      } else if (fields[i].value === 'extra5') {
        fieldrows.push(
          <FormGroup key={fields[i].text}>
            <FormControl
              type="text"
              placeholder={fields[i].text}
              onChange={e => {
                setExtraField5(e.target.value);
              }}
            />
          </FormGroup>
        );
      }
    }
    return fieldrows;
  }

  getVisibility(fieldname) {
    const { newDialog } = this.props;
    var topicObject = this.getTopicObject(newDialog.topic);
    if (topicObject && topicObject.tag && topicObject.tag.hide) {
      var fields = topicObject.tag.hide.fields;
      for (var i = 0, len = fields.length; i < len; i++) {
        if (fields[i].value === fieldname) {
          return false;
        }
      }
    }
    return true;
  }

  renderForm() {
    const {
      t,
      onSave,
      onCancel,
      setName,
      setPersadress,
      setEmail,
      setPoi,
      setTopic,
      setDetail,
      setDescription,
      topics,
      details,
      loadDetails,
      feedbacks,
      setFeedback,
      map,
      newDialog,
      setCenter,
      clearNewDialog,
      setWarning,
      instanceName,
      expand
    } = this.props;
    const feature = newDialog.obsadress.feature || newDialog.persadress.feature;
    let topicObject, detailObject;
    if (newDialog.topic) {
      topicObject = this.getTopicObject(newDialog.topic);
    }
    if (newDialog.detail) {
      detailObject = this.getDetailObject(newDialog.detail);
    }

    const { approvePrivacyPolicy, notifyPrivacyPolicy } = this.state;

    return (
      <React.Fragment>
        {topicObject &&
          topicObject.tag &&
          (topicObject.tag['wms_uri'] && topicObject.tag['wms_layer']) && (
            <WmsLayer
              id={String(topicObject.codeid)}
              key={String(topicObject.codeid)}
              uri={topicObject.tag['wms_uri']}
              layerName={topicObject.tag['wms_layer']}
              staticLayer
            />
          )}
        {detailObject &&
          detailObject.tag &&
          (detailObject.tag['wms_uri'] && detailObject.tag['wms_layer']) && (
            <WmsLayer
              id={String(detailObject.codeid)}
              key={String(detailObject.codeid)}
              uri={detailObject.tag['wms_uri']}
              layerName={detailObject.tag['wms_layer']}
              staticLayer
            />
          )}
        <DrawLayer
          olGeomType="Point"
          layerName="form"
          name="form"
          draw
          features={feature ? [feature] : []}
          onDrawend={feature => {
            const isMobileView = window.innerWidth < 768;
            if (isMobileView) {
              expand();
            }
            // var coords = feature.getGeometry().getFirstCoordinate();
            // coords = olUtils.transformCoordinate(
            //   coords,
            //   map.projCode,
            //   'EPSG:4326'
            // );
            setPoi({
              title: 'Pekt i kart',
              feature: olUtils.createFeatureFromGeometry(feature.getGeometry())
            });
          }}
        />

        <form className={['form'].join(' ')}>
          <FormGroup>
            <FormControl
              componentClass="select"
              onChange={e => {
                let t = this.getTopicObject(e.target.value);
                if (t && t.tag && t.tag.guidance) {
                  setWarning(t.tag.guidance);
                }
                setTopic(e.target.value);
                loadDetails(instanceName, e.target.value);
              }}
              defaultValue={t('topicPlaceholder')}
            >
              <option>{t('topicPlaceholder')}</option>
              {topics.map(topic => (
                <option key={topic.code} value={topic.code}>
                  {topic.code}
                </option>
              ))}
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl
              componentClass="select"
              onChange={e => {
                let t = this.getDetailObject(e.target.value);
                if (t && t.tag && t.tag.guidance) {
                  setWarning(t.tag.guidance);
                }
                setDetail(e.target.value);
              }}
              defaultValue={t('detailPlaceholder')}
              disabled={details.length < 1}
            >
              <option>{t('detailPlaceholder')}</option>
              {details.map(detail => (
                <option key={detail.code} value={detail.code}>
                  {detail.code}
                </option>
              ))}
            </FormControl>
          </FormGroup>
          <h4>{t('reporter')}:</h4>
          {// Insert extrafields if any
          (topicObject &&
            topicObject.tag &&
            topicObject.tag.add &&
            this.getExtraFields(topicObject.tag.add.fields)) ||
            (detailObject &&
              detailObject.tag &&
              detailObject.tag.add &&
              this.getExtraFields(detailObject.tag.add.fields))}

          {this.getVisibility('persphone') && (
            <FormGroup>
              <FormControl
                type="tel"
                placeholder={t('phonePlaceholder')}
                onChange={e => {
                  this.onPhoneNumberChange(e.target.value);
                }}
              />
            </FormGroup>
          )}
          {this.getVisibility('persname') && (
            <FormGroup>
              <FormControl
                type="text"
                placeholder={t('namePlaceholder')}
                onChange={e => {
                  setName(e.target.value);
                }}
                value={newDialog.persname}
              />
            </FormGroup>
          )}
          {this.getVisibility('persadress') && newDialog.persadress && (
            <FormGroup>
              <SearchField
                placeholder={t('persAdressPlaceHolder')}
                map={map}
                fieldKey="person"
                sources={_searchSources}
                onResultSelected={setPersadress}
                value={formatAdressTitle(newDialog.persadress)}
                setCenter={(coords, zoom) => {
                  if (!newDialog.obsadress.feature) {
                    setCenter(coords, zoom);
                  }
                }}
              />
            </FormGroup>
          )}
          {this.getVisibility('persemail') && (
            <FormGroup>
              <FormControl
                type="email"
                placeholder={t('emailPlaceholder')}
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </FormGroup>
          )}
          {this.getVisibility('feedbacktype') && (
            <FormGroup>
              <FormControl
                componentClass="select"
                onChange={e => {
                  setFeedback(e.target.value);
                }}
                defaultValue={t('feedbackPlaceholder')}
              >
                <option>{t('feedbackPlaceholder')}</option>
                {feedbacks.map(feedback => (
                  <option key={feedback.code} value={feedback.code}>
                    {feedback.code}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          )}
          <h4>{t('place')}:</h4>
          <FormGroup>
            <SearchField
              placeholder={
                newDialog.persadress.title || t('obsAdressPlaceHolder')
              }
              map={map}
              fieldKey="poi"
              sources={_searchSources}
              onResultSelected={setPoi}
              value={formatAdressTitle(newDialog.obsadress)}
              setCenter={setCenter}
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              componentClass="textarea" 
              rows="3"
              placeholder={t('descriptionPlaceholder')}
              onChange={e => {
                setDescription(e.target.value);
              }}
            />
          </FormGroup>
          <div className="privacy-policy-box">
            <div
              className={
                !approvePrivacyPolicy && notifyPrivacyPolicy
                  ? 'checkbox red'
                  : 'checkbox'
              }
              onClick={() =>
                this.setState({ approvePrivacyPolicy: !approvePrivacyPolicy })
              }
            >
              {approvePrivacyPolicy && <Icon name="checkmark" />}
            </div>
            <div
              className={
                !approvePrivacyPolicy && notifyPrivacyPolicy
                  ? 'text red'
                  : 'text'
              }
            >
              {t('approveMessage')}
              <a
                href="https://www.avinet.no/personvernerklaring.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('privacyPolicy')}
              </a>
            </div>
          </div>
          <FormGroup>
            <div className={'buttonBar rightAlign'}>
              <Button
                onClick={() => {
                  clearNewDialog();
                  onCancel();
                }}
              >
                {t('cancel')}
              </Button>
              <input
                type="button"
                className={
                  approvePrivacyPolicy
                    ? 'btn btn-primary'
                    : 'btn btn-primary disabled'
                }
                onClick={() => {
                  if (!approvePrivacyPolicy) {
                    this.setState({ notifyPrivacyPolicy: true });
                    return;
                  }
                  onSave();
                }}
                value={t('save')}
              />
            </div>
          </FormGroup>
        </form>
      </React.Fragment>
    );
  }

  renderReceipt() {
    const { t, mediaSaving } = this.props;
    const uploading = mediaSaving;
    return (
      <div className="receipt">
        <div className="receipt-header">Kvittering</div>
        <div>
          <p>
            Din henvendelse har fått meldingsnummer
            {
              <Link
                to={
                  window.dialogPrefix +
                  '/' +
                  this.props.instanceName +
                  '/' +
                  this.state.savedDialogId
                }
              >
                {' #' + this.state.savedDialogId}
              </Link>
            }
          </p>

          <p>
            Du kan også laste opp et bilde dersom du ønsker det. Bildet vil bli
            knyttet til meldingen, og vil kunne hjelpe kommunen i
            saksbehandlingen. Bildet vil ikke bli publisert offentlig.
          </p>
        </div>
        <div className="receipt-picture-button-container">
          <form>
            {uploading && (
              <div className={'loadSpinner'}>
                <Glyphicon glyph="refresh" className="rotating" />
                {' ' + t('SavingImage')}
              </div>
            )}
            {
              <UploadButton
                keyName="file-upload"
                recordUuid={this.state.savedDialogUuid}
                themeUuid={window.AdaptiveLight.config.meldingsDigiThemeUuid}
                labelText={t('AddImage')}
                onSaveSuccess={() =>
                  this.props.toast(
                    'imageUploaded',
                    'success',
                    t('ThankYouForContributing'),
                    true
                  )
                }
              />
            }
          </form>
          <div>
            Tilbake til{' '}
            <Link to={window.dialogPrefix + '/' + this.props.instanceName}>
              alle meldinger
            </Link>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { savedDialogId } = this.state;
    return (
      <div>
        {!savedDialogId && this.renderForm()}
        {savedDialogId && this.renderReceipt()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('DialogForm')(provideMapState(DialogForm)));
