import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import SamfunnsDialogView from './SamfunnsDialogView';
import SamfunnsDialogSuggestion from '../containers/SamfunnsDialogSuggestion';
import { history as utilHistory } from '../utils/history';
import * as routeActions from 'redux-simple-router';
import MediaQuery from 'react-responsive';

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, routeActions), dispatch);

const mapStateToProps = state => ({});

export class SamfunnsDialogSuggestionView extends React.Component {
  static propTypes = {
    params: PropTypes.any,
    history: PropTypes.object.isRequired
  };

  render() {
    const { history, match } = this.props;
    const { dialogId, instanceName } = match.params;
    return (
      <React.Fragment>
        <MediaQuery query="(max-width: 768px)">
          <SamfunnsDialogView hideList disableNavLinks={true} />
        </MediaQuery>

        <MediaQuery query="(min-width: 769px)">
          <SamfunnsDialogView disableNavLinks={true} />
        </MediaQuery>
        <SamfunnsDialogSuggestion
          id={parseInt(dialogId, 10)}
          instanceName={instanceName}
          history={utilHistory}
          onClose={() => {
            let path = window.dialogPrefix + '/' + instanceName;
            history.push(path);
          }}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('SamfunnsDialogSuggestionView')(withRouter(SamfunnsDialogSuggestionView)));
