import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Config/DialogConfig';
// import './utils/dialog/init-map.js';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { syncReduxAndRouter } from 'redux-simple-router';
import { history } from './utils/history';
import { store } from './redux/store';
import './styles/core.scss';
syncReduxAndRouter(history, store, state => state.router);

ReactDOM.render(<App store={store} />, document.getElementById('root'));
registerServiceWorker();
