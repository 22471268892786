import React from 'react';
import PropTypes from 'prop-types';

export default class Dot extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    className: PropTypes.string
  };

  render() {
    const { color, className } = this.props;
    return (
      <svg width="20" height="20" className={className}>
        <circle cx="6" cy="6" r={6} fill={color} />
      </svg>
    );
  }
}
