import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
export const SET_EXPANDED = 'SET_EXPANDED';

// ------------------------------------
// Actions
// ------------------------------------
export const expand = createAction(SET_EXPANDED, () => ({}));

export const actions = {
  expand
};

// -------------------------------
// Reducer
// -------------------------------
export default handleActions({
  SET_EXPANDED: (state) => {
    return Object.assign({}, state, {
      expanded: !state.expanded
    });
  }
}, {
  expanded: true
});
