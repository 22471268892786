import { createAction } from 'redux-actions';

// --------------------
// Constants
// --------------------
export const LOADED_SEARCH_SESSION = 'LOADED_SEARCH_SESSION';
export const LOADING_SEARCH_SESSION = 'LOADING_SEARCH_SESSION';

// ------------------------------------
// Actions
// ------------------------------------
const loadedSearchSession = createAction(LOADED_SEARCH_SESSION, data => ({
  data
}));

const loadingSearchSession = createAction(LOADING_SEARCH_SESSION, () => ({}));

export const loadSearchSession = () => {
  return (dispatch, getState) => {
    dispatch(loadingSearchSession());
    performSearchSessionLoad(dispatch);
  };
};

const performSearchSessionLoad = dispatch => {
  window.AdaptiveLight['search'] = {
    url:
      // window.AdaptiveLight.config.instanceUrl +
      'https://adialog.no/' + 
      'WebServices/search/SearchProxy.asmx/Search'
  };
  dispatch(loadedSearchSession());
};

export const actions = {
  loadSearchSession
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false
};

export default function(state = initialState, action) {
  if (!action) return state;

  switch (action.type) {
    case LOADING_SEARCH_SESSION:
      return Object.assign({}, state, {
        loading: true
      });

    case LOADED_SEARCH_SESSION:
      return Object.assign({}, state, {
        loading: false
      });
    default:
      return state;
  }
}
