
import uterom from '../icons/uterom.png';
import gange from '../icons/gange.png';
import kultur from '../icons/kultur.png';
import sykkel from '../icons/sykkel.png';
import annet from '../icons/annet.png';

const getColor = (topic) => {
    switch (topic.toLowerCase()) {
      case "uterom":
        return "#6AAF63";
      case "kulturmiljø":
        return "#3C9399";
      case "til fots": 
        return "#C37390";
      case "sykkel":
        return "#F17D54";
      case "annet":
        return "#EDBD4D";
      default: 
        return "#EDBD4D";
    }
  }

  const getIcon = (topic) => {
    switch (topic.toLowerCase()) {
      case "uterom":
        return uterom;
      case "til fots":
        return gange;
      case "kulturmiljø":
        return kultur;
      case "sykkel":
        return sykkel;
      case "annet":
        return annet;
      default: 
        return annet;
    }
  }

  export { getColor, getIcon };