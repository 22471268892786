import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import Toast from '../components/Toast';
import { actions as toastActions } from '../redux/modules/toast';

const mapDispathToProps = dispatch =>
  bindActionCreators(Object.assign({}, toastActions), dispatch);

const mapStateToProps = state => ({
  show: state.toast.show,
  message: state.toast.message,
  type: state.toast.type,
  dismissable: state.toast.dismissable,
  description: state.toast.description
});

// TODO Make search box oblivious of current search source
// OR make it possible to select search source
// OR make it possible to have multiple search sources

export class ToastService extends React.Component {
  get message() {
    const { t, message, description } = this.props;
    if (!description) {
      return '<h4>' + t(message) + '</h4>';
    }
    return '<h4>' + t(message) + '</h4><span>' + description + '</span>';
  }

  render() {
    const { show, message, type, dismissable } = this.props;
    return (
      <div>
        {message && (
          <Toast
            show={show}
            message={this.message}
            type={type}
            onAlertDismiss={dismissable && this.props.toastReset}
          />
        )}
      </div>
    );
  }
}

ToastService.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dismissable: PropTypes.bool.isRequired,
  description: PropTypes.string,
  toastReset: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(translate('ToastService')(ToastService));
