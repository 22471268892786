import React from 'react';
import PropTypes from 'prop-types';

class IndexLayout extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }

  render() {
    const { children } = this.props;
    return (
      <div className="index-layout--root">
        <div className="index-layout--container">
          <div className="index-layout--components">{children}</div>
        </div>
      </div>
    );
  }
}

IndexLayout.propTypes = {
  children: PropTypes.element
};

export default IndexLayout;
