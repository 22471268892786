import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import MapLayout from './Layout/MapLayout';
import IndexLayout from './Layout/IndexLayout';
import DialogView from './views/DialogView';
import SamfunnsDialogView from './views/SamfunnsDialogView';
import AboutSamfunnsDialogView from './views/AboutSamfunnsDialogView';
import AddToHomeScreenView from './views/AddToHomeScreenView';
import LandingPageView from './views/LandingPageView';
import DialogCreateNewView from './views/DialogCreateNewView';
import SamfunnsDialogCreateNewView from './views/SamfunnsDialogCreateNewView';
import DialogMessagesView from './views/DialogMessagesView';
import SamfunnsDialogSuggestionView from './views/SamfunnsDialogSuggestionView';

class AppRouter extends React.Component {
  state = {
    hideList: true
  }
  
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/:instanceName">
            <MapLayout title={this.props.instance ? this.props.instance.name : 'Avinet'}>
              <React.Fragment>
                {this.props.children}
                <Switch>
                  <Route path="/dialog">
                    <Switch>
                      <Route path="/dialog/meland">
                        <Redirect to="/dialog/alver" />
                      </Route>
                      <Route
                        path="/dialog/:instanceName/ny"
                        component={DialogCreateNewView}
                      />
                      <Route
                        path="/dialog/:instanceName/:dialogId(\d+)"
                        component={DialogMessagesView}
                      />
                      <Route
                        path="/dialog/:instanceName"
                        component={() => <DialogView hideList={this.state.hideList} setListVisibility={(v) => this.setState({hideList: v})}/>}
                      />
                    </Switch>
                  </Route>
                  <Route path="/samfunnsdialog">
                    <Switch>
                      <Route
                        path="/samfunnsdialog/:instanceName/ny"
                        component={SamfunnsDialogCreateNewView}
                      />
                      <Route
                        path="/samfunnsdialog/:instanceName/:dialogId(\d+)"
                        component={SamfunnsDialogSuggestionView}
                      />
                      <Route
                        path="/samfunnsdialog/:instanceName/om-samfunnsdialog"
                        component={AboutSamfunnsDialogView}
                      />
                      <Route
                        path="/samfunnsdialog/:instanceName/legg-til-hjemmeskjerm"
                        component={AddToHomeScreenView}
                      />
                      <Route
                        path="/samfunnsdialog/:instanceName"
                        component={() => <SamfunnsDialogView hideList={this.state.hideList} setListVisibility={(v) => this.setState({hideList: v})}/>}
                      />
                    </Switch>
                  </Route>
                  <Route exact path="/meland"><Redirect to="/alver"/></Route>
                  <Route
                    path="/:instanceName/ny"
                    component={DialogCreateNewView}
                  />
                  <Route
                    path="/:instanceName/:dialogId(\d+)"
                    component={DialogMessagesView}
                  />
                  <Route path="/:instanceName" exact component={DialogView} /> 
                  {/* <Route path="/" exact component={Landing} /> */}
                  {/* <Route
                  path="/rediger/:dialogId"
                  component={DialogMessagesView}
                /> */}
                </Switch>
              </React.Fragment>
            </MapLayout>
          </Route>

          {/* <Route path="/dialog">
            <IndexLayout>
              <Switch>
                <Route path="/" component={LandingPageView} />
              </Switch>
            </IndexLayout>
          </Route> */}
          <Route path="/">
            <IndexLayout>
              <Switch>
                <Route path="/" exact component={LandingPageView} />
              </Switch>
            </IndexLayout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
