import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-translate';
import { actions as dialogActions } from '../redux/modules/dialog/dialogs';
import { VectorSource } from 'react-openlayers';
import DialogList from '../components/DialogList';
import * as routeActions from 'redux-simple-router';
import { posStatus } from '../utils/dialog/dialog';
import { actions as mapActions } from '../redux/modules/map';

import { Style, Fill, Stroke, Circle } from 'ol/style';
import { getFeatureStyle } from '../utils/icons';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    Object.assign({}, dialogActions, mapActions, routeActions),
    dispatch
  );

const mapStateToProps = state => ({
  dialogs: state.dialogs.dialogs,
  dialogsLoadState: state.dialogs.loadState,
  dialogsLoading: state.dialogs.loading,
  mapCenter: state.map.center,
  mapZoom: state.map.zoom,
  projCode: state.map.projCode
});

export class Dialog extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loadDialogs: PropTypes.func.isRequired,
    dialogs: PropTypes.array,
    dialogsLoadState: PropTypes.object,
    dialogsLoading: PropTypes.bool,
    mapCenter: PropTypes.any,
    mapZoom: PropTypes.number,
    projCode: PropTypes.string,
    history: PropTypes.object.isRequired,
    readBaseUrl: PropTypes.string.isRequired,
    setCenter: PropTypes.func,
    instanceName: PropTypes.string,
    dialogId: PropTypes.number,
    topicsFilter: PropTypes.array
  };

  state = {
    currentFilterString: ""
  }

  componentDidMount(props) {
    const { instanceName } = this.props;
    this.props.loadDialogs(instanceName);
  }

  componentDidUpdate(prevProps) {
    if (this.props.topicsFilter.length > 0) {
        var topicsToFilter = this.props.topicsFilter.filter((t) => {if (!t.active) {return true}});
        const filterString = topicsToFilter.map(function(t) { return t.key }).join(";");
        if (filterString !== this.state.currentFilterString) {
          this.setState({ currentFilterString: filterString });
          this.props.loadDialogs(this.props.instanceName, filterString);
        }
    }
  }

  get statusFeatures() {
    const { dialogs, history, instanceName, dialogId } = this.props;
    let statuses = {};
    posStatus.forEach(status => {
      statuses[status.dataIndex] = Object.assign(status, { features: [] });
    });

    dialogs.forEach(dialog => {
      if (dialogId === dialog.id) {
        dialog.feature.set('active', true);
      } else {
        dialog.feature.set('active', false);
      }
      const style = getFeatureStyle(dialog.topic, statuses[dialog.status].fillColor, dialog.feature);
      dialog.feature.set('fillcolor', statuses[dialog.status].fillColor);
      dialog.feature.setStyle(style);
      if (statuses[dialog.status]) {
        dialog.feature.onClick = () => {
          history.push(
            window.dialogPrefix + '/' + instanceName + '/' + dialog.id
          );
        };
        statuses[dialog.status].features.push(dialog.feature);
        dialog['fillColor'] = statuses[dialog.status].fillColor;
        dialog['strokeColor'] = statuses[dialog.status].strokeColor;
        dialog['fillOpacity'] = statuses[dialog.status].fillOpacity;
        dialog['strokeOpacity'] = statuses[dialog.status].strokeOpacity;
        dialog['strokeWidth'] = statuses[dialog.status].strokeWidth;
      }
    });
    return statuses;
  }

  render() {
    const { dialogs, history, instanceName } = this.props;
    const statuses = this.statusFeatures;
    return (
      <div>
        {Object.values(statuses).map(status => {
          return (
            <VectorSource
              key={status.dataIndex}
              layerName={status.dataIndex}
              features={status.features}
            />
          );
        })}
        <DialogList
          dialogs={dialogs}
          onDialogOpen={dialog => {
            history.push(
              window.dialogPrefix + '/' + instanceName + '/' + dialog.id
            );
            dialog.feature.set('active', true);
            let padding = [0, 0, 0, 0];
            if (window.innerWidth > 768) {
              padding[1] = 350;
            }
          }}
          isLoading={this.props.dialogsLoading}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('Dialog')(withRouter(Dialog)));
