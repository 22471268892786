import { handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
export const TOAST = 'TOAST';
export const TOAST_RESET = 'TOAST_RESET';

// ------------------------------------
// Actions
// ------------------------------------
export const toast = (message, type, description, dismissable, delay) => {
  return (dispatch, getState) => {
    dispatch({ type: TOAST, payload: { message, type, description, dismissable } });
    if (!dismissable) {
      setTimeout(() => {
        dispatch({ type: TOAST_RESET });
      }, delay || 3000);
    }
  };
};

export const toastReset = () => {
  return (dispatch, getState) => {
    dispatch({ type: TOAST_RESET });
  };
};

export const actions = {
  toast,
  toastReset
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  TOAST: (state, { payload }) => ({ ...state, ...payload, show: true }),
  TOAST_RESET: (state) => ({ ...state, show: false })
}, {
  show: false,
  message: '',
  description: undefined,
  type: 'success',
  dismissable: false
});
