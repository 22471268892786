import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import { Button, Glyphicon } from 'react-bootstrap';
// import { actions as mapActions } from '../redux/modules/map';
import { actions as dialogActions } from '../redux/modules/dialog/samfunnsdialog';
import Modal from '../components/Modal';
import { getIcon } from '../utils/samfunnsdialogHelpers';
import { actions as mediaActions } from '../redux/mediaReducer';
import { VectorLayer, provideMapState } from 'react-openlayers';
import './DialogMessages.scss';
import * as routeActions from 'redux-simple-router';

const mapDispathToProps = dispatch =>
  bindActionCreators(Object.assign({}, dialogActions, routeActions, mediaActions), dispatch);

const mapStateToProps = state => ({
  dialogId: state.samfunnsdialog.id,
  dialog: state.samfunnsdialog.dialog,
  samfunnsDialogRefreshNeeded: state.samfunnsdialog.samfunnsDialogRefreshNeeded,
  dialogLoading: state.samfunnsdialog.loading,
  mapZoom: state.map.zoom,
  expanded: state.menuButton.expanded,
  media: state.mediaReducer.records,
  mediaObjectUuid: state.mediaReducer.objectUuid
});

const ImageRender = ({ imageUrl, onClose }) => {
  return (
    <div className={"modalImageContainer"} onClick={() => onClose()}>
      <img style={{maxHeight:'90vh', maxWidth: '90vw', objectFit: 'contain'}} src={imageUrl}></img>
    </div>
  )
}

export class SamfunnsDialogSuggestion extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number,
    dialogId: PropTypes.number,
    instanceName: PropTypes.string,
    readBaseUrl: PropTypes.string,
    dialog: PropTypes.object,
    dialogLoading: PropTypes.bool,
    pushPath: PropTypes.func.isRequired,
    loadDialog: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    setCenter: PropTypes.func.isRequired,
    fitViewAndZoom: PropTypes.func.isRequired,
    mapZoom: PropTypes.number,
    expanded: PropTypes.bool
  };

  state = {
    showImageUrl: undefined
  }

  componentDidMount() {
    this.checkDialog(this.props);
  }

  componentDidUpdate(prevProps) {
    this.checkDialog(this.props);
    if (this.props.id && this.props.dialog) {
      this.highlightDialog(this.props.dialog); 
      if (this.props.dialog.uuid !== this.props.mediaObjectUuid) {
        this.props.readMedia(this.props.dialog.uuid, 32633, window.AdaptiveLight.config.samfunnsDigiThemeUuid);
      }   
    }    
  }

  unhighlightDialog(dialog) {
    if (dialog) {
      dialog.active = false;
      if (dialog.feature) {
        dialog.feature.set('active', false);
      }
    }
  }

  highlightDialog(dialog) {
    if (dialog && !dialog.active) {
      this.unhighlightDialog(dialog);
      dialog.active = true;

      if (dialog.feature) {
        dialog.feature.set('active', true);

        // const zoom = this.props
        //   .mapZoom; /* Math.max(this.props.mapZoom || 0, 18); */
        // let padding = [0, 0, 0, 0];

        // if (window.innerWidth > 768) {
        //   padding[1] = 350;
        // }
        const zoom = 20;
        setTimeout(() => {
          this.props.setCenter(
            dialog.feature.getGeometry().getFirstCoordinate(),
            zoom
          );
        }, 250);
      }
    }
  }

  checkDialog(props) {
    const { id, dialogId, loadDialog, samfunnsDialogRefreshNeeded } = props;
    
    if (dialogId !== id || samfunnsDialogRefreshNeeded) {
      loadDialog(id);
      return;
    }
  }

  formatDateTime(date) {
    const locale = this.props.t('locale');
    const opts = {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    };
    return date.toLocaleString(locale, opts);
  }

  formatDate(date) {
    const locale = this.props.t('locale');
    const opts = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    };
    return date.toLocaleString(locale, opts);
  }

  renderDialogMessage(dialogMessage) {
    return (
      <div className={'dialogMessage'} key={dialogMessage.id}>
        <div className={'table'}>
          <div>
            <div className={'date'}>
              {this.formatDateTime(dialogMessage.date)}
            </div>
            <div className={'dialogMessageBody'}>{dialogMessage.comment}</div>
          </div>
        </div>
      </div>
    );
  }

  renderMedia(m, i) {
    return (
      <div className={'row'} key={m.id}>
        <div className={'atr imagetext'}>
          Bilde {i + 1}
        </div>
        <Button
              bsStyle="default"
              onClick={() => this.setState({ 
                showImageUrl: window.AdaptiveLight.config.instanceUrl + 'WebServices/generic/Media.asmx/Download?uuid=' + m.uuid})
                }
              title="Åpne"
            >
              <span>Åpne</span>
        </Button>
      </div>
    );
  }



  render() {
    const { dialogId, dialog, dialogLoading, onClose, t, media } = this.props;

    if (!dialog) {
      return null;
    }

    const showImageUrl = this.state.showImageUrl;
    var logs = [...dialog.logs]; //Copy array here so we dont augment original array in reducer
    var description = (logs && logs.length > 0) ? logs[0].comment : "";
    var comments = (logs && logs.length > 0) ? logs.splice(1) : [];
    const icon = getIcon(dialog.topic);

    return (
      // <div className="window-overlay">
      <div className={['', 'window', dialogLoading ? 'loading' : ''].join(' ')}>
        <div className={'headerBar'}>
          <div className="headerBar-image">
          <img src={icon} alt={dialog.topic + '-ikon'} />
          </div>
          <h4 className={'header'}>
            {t('message')} #{dialogId}
          </h4>
          <div className={'buttonBar rightAlign'}>
            <Button
              bsStyle="default"
              onClick={() => {
                let padding = [0, 0, 0, 0];
                if (window.innerWidth > 768) {
                  padding[1] = 350;
                }
                this.props.setCenter(
                  dialog.feature.getGeometry().getFirstCoordinate(),
                  16
                );
              }}
              title={t('zoomIn')}
            >
              <Glyphicon glyph="zoom-in" />
            </Button>
            <Button
              bsStyle="default"
              onClick={() => {
                dialog.feature.set('active', false);
                onClose();
              }}
              title={t('close')}
            >
              <Glyphicon glyph="remove" />
            </Button>
          </div>
        </div>

        {dialog && (
            <VectorLayer
              key={'dialogMessage'}
              layerName={'dialogMessage'}
              features={[dialog.feature]}
              fillColor={dialog.fillColor}
              staticLayer
            />
          ) && (
            <div className={'tableContainer'}>
              <div className={'table'}>
                <div className={'row'}>
                  <div className={'atr'}>{t('date')}</div>
                  <div className={'val'}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.formatDate(dialog.date)
                      }}
                    />
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'atr'}>{t('topic')}</div>
                  <div className={'val'}>{dialog.topic}</div>
                </div>
                <div className={'row'}>
                  <div className={'atr'}>{t('status')}</div>
                  <div className={'val'}>
                    {dialog.treatment}
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'atr'}>{t('description')}</div>
                  <div className={'val'}>{description}</div>
                </div>
              </div>
              {dialog.countpublicmessages > 1 && (
                <div className={'comment'}>{t('comment')}</div>
              )}
              {media && media.length > 0 && (
                <div className="table">
                  {media.map((m, i) => this.renderMedia(m, i))}
                </div>
              )}
              {comments && comments.length > 0 && (
                <div className="table">
                  {comments.map(log => this.renderDialogMessage(log))}
                </div>
              )}
              {showImageUrl && (
                <Modal>
                  <ImageRender 
                    imageUrl={showImageUrl}
                    onClose={() => this.setState({ showImageUrl: undefined})}
                  />
                </Modal>
              )}
            </div>
            
          
          )}
      </div>
      // </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispathToProps
)(translate('SamfunnsDialogSuggestion')(provideMapState(SamfunnsDialogSuggestion)));
