import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';
import { withRouter } from 'react-router-dom';
import { actions as dialogActions } from '../redux/modules/dialog/dialogs';
import DialogForm from '../components/DialogForm';
import * as routeActions from 'redux-simple-router';
import { Alert } from 'react-bootstrap';
import './DialogCreateNew.scss';

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, dialogActions, routeActions), dispatch);

const mapStateToProps = state => ({
  dialogSaving: state.dialogs.newDialog.saving,
  saveSuccess: state.dialogs.newDialog.saveSuccess,
  newDialogId: state.dialogs.newDialog.id
});

export class DialogCreateNew extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    readBaseUrl: PropTypes.string.isRequired,
    instanceName: PropTypes.string,
    saveDialog: PropTypes.func,
    dialogsSaving: PropTypes.bool,
    saveSuccess: PropTypes.bool,
    newDialogId: PropTypes.number
  };

  constructor() {
    super();
    this.state = {};
  }

  getParagraphStrings(message) {
    return message.split('<br/>');
  }

  render() {
    const { history, instanceName, saveDialog } = this.props;
    const { message, alertVisible } = this.state;
    return (
      <div className="clearfix">
        {message && alertVisible && (
          <div className="warningBox">
            <Alert
              bsStyle="info"
              onDismiss={() => this.setState({ alertVisible: false })}
            >
              {this.getParagraphStrings(message).map((paragraph, i) => (
                <p key={i}>
                  <strong>{paragraph}</strong>
                </p>
              ))}
            </Alert>
          </div>
        )}
        <DialogForm
          onSave={() => saveDialog(instanceName)}
          onCancel={() =>
            history.push(window.dialogPrefix + '/' + instanceName)
          }
          instanceName={instanceName}
          setWarning={message => {
            this.setState({ message: message, alertVisible: true });
          }}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('DialogCreateNew')(withRouter(DialogCreateNew)));
